import React from 'react';
import './PoCreate.css';

function SupplyFieldsDiv({ supplyDetails, selectedSupplierAddresses }) {
   
    // Check if supplyDetails is not provided
    if (!supplyDetails || Object.keys(supplyDetails).length === 0) {
        return null; // Return null or some default content if supplyDetails is not provided or empty
    }

    const defaultFieldsData = supplyDetails;
    const fields = Object.keys(defaultFieldsData);

    return (
        <>
        <div className='supply-fields-heads-details' style={{paddingBottom:'5px'}}>
            <span className='supply-field-key'>Supplier address:</span>
            <span className='supply-field-value'>{selectedSupplierAddresses.label?selectedSupplierAddresses.label:selectedSupplierAddresses.address}</span>
        </div>
          <div className='supply-fields-heads'>
            <div className='supply-fields-heads-left'>
                {fields.map((fieldName, index) => (
                    index % 2 === 0 && defaultFieldsData[fieldName].value && ( // Added condition to check if value exists
                        <div className='supply-fields-heads-details' key={index}>
                            <span className='supply-field-key'>{defaultFieldsData[fieldName].fieldName}:</span>
                            {supplyDetails[fieldName].inputType === 'link' ? (
                                <a
                                    href={supplyDetails[fieldName].value.includes('http') ? supplyDetails[fieldName].value : `https://${supplyDetails[fieldName].value}`}
                                    target="_blank"
                                    className="form-link"
                                >
                                    {supplyDetails[fieldName].value}
                                </a>
                            ) : supplyDetails[fieldName].inputType === 'multi_select' ? (
                                <span className='supply-field-value'>
                                    {supplyDetails[fieldName].value.join(', ')}
                                </span>
                            ) : supplyDetails[fieldName].inputType === 'person' ? (
                                <span className='supply-field-value'>
                                    {supplyDetails[fieldName].value.fullName}
                                </span>
                            ):supplyDetails[fieldName].inputType === 'address' ? (
                                <div className='supply-field-value'>
                                    <span>{selectedSupplierAddresses}</span>
                                </div>
                            ) :supplyDetails[fieldName].inputType === 'date_range' ? (
                                <div className='supply-field-value'>
                                     <span>{`${supplyDetails[fieldName].value.start} to ${supplyDetails[fieldName].value.end}`}</span>
                                </div>
                            ) : (
                                <span className='supply-field-value'>
                                    {supplyDetails[fieldName].value}
                                </span>
                            )}
                        </div>
                    )
                ))}
            </div>
            <div className='supply-fields-heads-left'>
                {fields.map((fieldName, index) => (
                    index % 2 !== 0 && defaultFieldsData[fieldName].value && ( // Added condition to check if value exists
                        <div className='supply-fields-heads-details' key={index}>
                            <span className='supply-field-key'>{defaultFieldsData[fieldName].fieldName}:</span>
                            {supplyDetails[fieldName].inputType === 'link' ? (
                                <a
                                    href={supplyDetails[fieldName].value.includes('http') ? supplyDetails[fieldName].value : `https://${supplyDetails[fieldName].value}`}
                                    target="_blank"
                                    className="form-link"
                                >
                                    {supplyDetails[fieldName].value}
                                </a>
                            ) : supplyDetails[fieldName].inputType === 'multi_select' ? (
                                <span className='supply-field-value'>
                                    {supplyDetails[fieldName].value.join(', ')}
                                </span>
                            ) : supplyDetails[fieldName].inputType === 'address' ? (

                                <div className='supply-field-value'>
                                    <span>{selectedSupplierAddresses}</span>
                                </div>

                            ) :supplyDetails[fieldName].inputType === 'person' ? (
                                <span className='supply-field-value'>
                                    {supplyDetails[fieldName].value.fullName}
                                </span>
                            ): (
                                <span className='supply-field-value'>
                                    {supplyDetails[fieldName].value}
                                </span>
                            )}
                        </div>
                    )
                ))}
            </div>
        </div>
        </>
      
    );
}

export default SupplyFieldsDiv;
