import React, { useState, useEffect } from 'react';
import { ASSET_PREFIX_URL, BASE_URL, HEADERS, SUPLIST } from '../../constants/config';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './SupplierPage.css';
import MemberDropdown from '../Common/MemberDropdown';
import { toast } from 'react-toastify';


function AddressField({ addresses, onAddAddress, onEditAddress, onDeleteAddress, onAddressSave }) {
    console.log(addresses)
    const [editingIndex, setEditingIndex] = useState(null);

    const handleEditClick = (index) => {
        setEditingIndex(index);
    };

    const handleSaveClick = (addressId) => {
        // console.log(addressId)
        if (addressId._id) {
            console.log('edit', addressId.address, addressId._id)
            // onAddressSave('edit',addressId.address,addressId._id,)
        } else {
            console.log('save')
            // onAddressSave('save',addressId)
        }

        setEditingIndex(null);
    };

    const handleAddAddressClick = () => {
        onAddAddress();
        setEditingIndex(addresses.length); // Set editingIndex to the newly added address
    };

    return (
        <div className='address-field-container'>
            {addresses.map((address, index) => (
                <div key={index} className='address-field'>
                    <textarea
                        className='supply-textarea'
                        placeholder='Enter address'
                        value={address.address}
                        onChange={(e) => onEditAddress(index, e.target.value)}
                        disabled={editingIndex !== index}
                    />

                    {editingIndex === index ? (
                        <img
                            onClick={() => handleSaveClick(address)}
                            src={`${ASSET_PREFIX_URL}save-details.png`}
                            alt="save"
                            width='24px'
                            height='24px'
                            style={{ cursor: 'pointer' }}
                        />
                    ) : (
                        <img
                            onClick={() => handleEditClick(index)}
                            src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                            alt="Edit"
                            width="24px"
                            height="24px"
                            style={{ cursor: 'pointer' }}
                        />
                    )}

                    <img
                        onClick={() => onDeleteAddress(index)}
                        src={`${ASSET_PREFIX_URL}attri-delete.png`}
                        alt="delete"
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            ))}
            <button onClick={handleAddAddressClick} className="add-alternate-address-btn">
                Add more Address
            </button>
        </div>
    );
}



function SupplierPageView() {
    const nav = useNavigate();
    const { sequence_id } = useParams();
    const [data, setData] = useState({});
    const [formData, setFormData] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [editedField, setEditedField] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    useEffect(() => {
        fetchSupplierDetails();
    }, []);

    const fetchSupplierDetails = async () => {
        try {
            const headers = HEADERS
            const response = await axios.get(`${BASE_URL}/v1/supply-chain/get-supplier-details`, {
                headers,
                params: { sequence_id: sequence_id }
            });

            setData(response.data.data.supplier_details.data);
            setFormData(response.data.data.supplier_details.data);
            setAddresses(response.data.data.supplier_details.addresses)

        } catch (error) {
            console.error('Error fetching options data:', error);
        }
    };

    const handleBack = () => {
        nav(SUPLIST);
    };

    const handleChange = (fieldKey, value) => {
        setFormData(prevState => ({
            ...prevState,
            [fieldKey]: value
        }));
        console.log(fieldKey)
    };


    const handleEdit = (fieldKey) => {
        setEditedField(fieldKey);
    };

    const handleCancelEdit = () => {
        setEditedField(null);
    };

    const handleSaveChanges = async (fieldKey) => {
        console.log(fieldKey)
        const isDefaultField = data.default_fields_data.hasOwnProperty(fieldKey);
        const previousData = {
            [fieldKey]: isDefaultField
                ? { ...data.default_fields_data[fieldKey] }
                : { ...data.custom_fields_data[fieldKey] }
        };

        const updatedData = {
            [fieldKey]: isDefaultField
                ? { ...data.default_fields_data[fieldKey], value: formData[fieldKey] }
                : { ...data.custom_fields_data[fieldKey], value: formData[fieldKey] }
        };

        if (previousData[fieldKey].inputType === 'date_range') {
            previousData[fieldKey].value = {
                start: data.default_fields_data[fieldKey]?.value.start || data.custom_fields_data[fieldKey]?.value.start,
                end: data.default_fields_data[fieldKey]?.value.end || data.custom_fields_data[fieldKey]?.value.end
            };
        }

        if (updatedData[fieldKey].inputType === 'date_range') {
            updatedData[fieldKey].value = {
                start: formData[fieldKey]?.start || '',
                end: formData[fieldKey]?.end || ''
            };
        }

        console.log('Previous Data:', previousData);
        console.log('Updated Data:', updatedData);

        setEditedField(null);
        setEditMode(false);

        try {
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };

            await axios.post(
                `${BASE_URL}/v1/supply-chain/edit-supplier-details`,
                {
                    sequence_id: sequence_id,
                    prev_obj: JSON.stringify(previousData),
                    new_obj: JSON.stringify(updatedData),
                },
                { headers }
            );

        } catch (error) {
            console.log(error);
        }
    };


    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            border: "1px solid #edf2f7",
            boxShadow: "none",
            color: 'black',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            background: "white",
            color: "black",
            cursor: "pointer",
            ":hover": {
                background: "#610BEE",
                color: "white",
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "black",
            padding: "5px 10px",
            borderRadius: "4px",
            fontSize: "14px",
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: "14px",
            color: "black",
        }),
        menu: (provided) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            color: "black",
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: "white",
        }),
    };

    const watchersChange = (key, selected) => {
        const labelValue = selected.map((item) => {

            return { photo: item.photo ? item.photo : '', member_id: item.value, fullName: item.fullNmae };
        });
        console.log(key)
        setFormData(prevState => ({
            ...prevState,
            [key]: labelValue
        }));
    };
    const handleSinglePersonChange = (key, selected) => {
        console.log(selected, key)
        const labelValue = { photo: selected.photo ? selected.photo : '', member_id: selected.value, fullName: selected.fullName };

        console.log(labelValue)
        setFormData(prevState => ({
            ...prevState,
            [key]: labelValue
        }));
    };
    const renderFields = (fields) => {
        if (!fields) return null;

        return Object.keys(fields).map((fieldKey) => {
            const field = fields[fieldKey];
            if (!field.value) return null;

            return (
                <div key={fieldKey} className='supplier-mandatory-field-container'>
                    <span className='supply-custom-label-span'>{field.fieldName}:</span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                        {editedField === fieldKey ? (
                            <>
                                {(field.inputType === 'text' || field.inputType === 'email' || field.inputType === 'gst' ||
                                    field.inputType === 'team' || field.inputType === 'pincode') && (
                                        <>
                                            <input
                                                type='text'
                                                placeholder={field.placeholder || ''}
                                                value={formData[fieldKey] || field.value || ''}
                                                onChange={(e) => handleChange(fieldKey, e.target.value)}
                                            />
                                            <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                            <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        </>
                                    )}
                                {(field.inputType === 'address') && (
                                    <>
                                        <AddressField
                                            addresses={formData[fieldKey] || field.value || []}
                                            onAddAddress={() => handleChange(fieldKey, [...(formData[fieldKey] || field.value || []), ''])}
                                            onEditAddress={(index, value) => {
                                                const updatedAddresses = [...(formData[fieldKey] || field.value || [])];
                                                updatedAddresses[index] = value;
                                                handleChange(fieldKey, updatedAddresses);
                                            }}
                                            onDeleteAddress={(index) => {
                                                const updatedAddresses = [...(formData[fieldKey] || field.value || [])];
                                                updatedAddresses.splice(index, 1);
                                                handleChange(fieldKey, updatedAddresses);
                                            }}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                                {(field.inputType === 'paragraph') && (
                                    <>
                                        <textarea
                                            className='supply-textarea'
                                            placeholder={field.placeholder || ''}
                                            value={formData[fieldKey] || field.value || ''}
                                            onChange={(e) => handleChange(fieldKey, e.target.value)}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                                {field.inputType === 'PhoneNumber' && (
                                    <>
                                        <PhoneInput
                                            style={{ width: '300px' }}
                                            placeholder={field.placeholder || ''}
                                            value={formData[fieldKey] || field.value || ''}
                                            onChange={(value) => handleChange(fieldKey, value)}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                                {field.inputType === 'single_select' && (
                                    <>
                                        <Select
                                            styles={customStyles}
                                            options={field.option.map(option => ({ value: option, label: option }))}
                                            value={{ value: formData[fieldKey] || field.value, label: formData[fieldKey] || field.value }}
                                            onChange={(selectedOption) => handleChange(fieldKey, selectedOption.value)}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                                {field.inputType === 'date' && (
                                    <>
                                        <input
                                            type='date'
                                            placeholder={field.placeholder || ''}
                                            value={formData[fieldKey] || field.value || ''}
                                            onChange={(e) => handleChange(fieldKey, e.target.value)}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                                {field.inputType === 'date_range' && (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '500px' }}>
                                        <div className="type-texts" style={{ width: '290px' }}>
                                            <div style={{ width: '200px' }}>
                                                <span>Start Date</span>
                                                <input
                                                    placeholder={field.placeholder}
                                                    type="date"
                                                    style={{ width: '100%' }}
                                                    value={formData[fieldKey]?.start || field.value?.start || ''}
                                                    // value={formData[field.fieldName]?.start || field.value.start}
                                                    min={new Date().toISOString().split("T")[0]}
                                                    onChange={(e) =>
                                                        handleChange(fieldKey, {
                                                            ...formData[field.fieldName],
                                                            start: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div style={{ width: '200px' }}>
                                                <span>End Date</span>
                                                <input
                                                    type="date"
                                                    style={{ width: '100%' }}
                                                    value={formData[fieldKey]?.end || field.value?.end || ''}
                                                    min={formData[field.fieldName]?.start || field.value?.start}
                                                    onChange={(e) =>
                                                        handleChange(fieldKey, {
                                                            ...formData[field.fieldName],
                                                            end: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                            <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        </div>
                                    </div>
                                )}
                                {field.inputType === 'multi_person' || field.inputType === 'person' && (
                                    <>
                                        <div style={{ width: "292px", }}>
                                            <MemberDropdown field={{
                                                fieldName: field.fieldName.replace(/\s+/g, '_').toLowerCase(),
                                                placeholder: field.placeholder,
                                                isMulti: field.inputType === 'multi_person' ? true : false,
                                                value: formData[fieldKey] ? formData[fieldKey] : field.value
                                            }}
                                                handlePersonSelectChange={field.inputType === 'multi_person' ? watchersChange : handleSinglePersonChange} disable={false} />

                                        </div>
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>

                                )}
                                {field.inputType === 'number' && (
                                    <>
                                        <input
                                            type='number'
                                            placeholder={field.placeholder || ''}
                                            value={formData[fieldKey] || field.value || ''}
                                            onChange={(e) => handleChange(fieldKey, e.target.value)}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                                {field.inputType === 'link' && (
                                    <>
                                        <input
                                            type='text'
                                            placeholder={field.placeholder || ''}
                                            value={formData[fieldKey] || field.value || ''}
                                            onChange={(e) => handleChange(fieldKey, e.target.value)}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                                {field.inputType === 'multi_select' && (
                                    <>
                                        <Select
                                            styles={customStyles}
                                            isMulti
                                            options={field.option.map(option => ({ value: option, label: option }))}
                                            value={(formData[fieldKey] || field.value || []).map(val => ({ value: val, label: val }))}
                                            onChange={(selectedOptions) => handleChange(fieldKey, selectedOptions.map(option => option.value))}
                                        />
                                        <img onClick={() => handleSaveChanges(fieldKey)} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                        <img onClick={handleCancelEdit} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {field.inputType === 'link' ? (
                                    <a
                                        href={field.value.includes('http') ? field.value : `https://${field.value}`}
                                        target="_blank"
                                        className="form-link"
                                    >
                                        {field.value}
                                    </a>
                                ) : field.inputType === 'multi_person' || field.inputType === 'person' ? (
                                    <div style={{ display: 'flex', gap: '8px', flexDirection: 'column', width: '292px' }}>
                                        <MemberDropdown field={{
                                            fieldName: field.fieldName.replace(/\s+/g, '_').toLowerCase(),
                                            placeholder: field.placeholder,
                                            isMulti: field.inputType === 'multi_person' ? true : false,
                                            value: field.value
                                        }} fieldKey={fieldKey} disable={true} />
                                    </div>
                                ) : field.inputType === 'date_range' ? (
                                    <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
                                        <input type="text" value={field.value?.start || ''} readOnly />
                                        <input type="text" value={field.value?.end || ''} readOnly />
                                    </div>
                                ) : (
                                    <input value={formData[fieldKey] || field.value} disabled />
                                )}
                                <img
                                    onClick={() => handleEdit(fieldKey)}
                                    src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                    alt="Edit"
                                    width="24px"
                                    height="24px"
                                    style={{ cursor: 'pointer' }}
                                />
                            </>
                        )}
                    </div>
                </div>
            );
        });
    };
    const updateAddress = async (action, address, id) => {
        try {
           const response = await axios.post(
                `${BASE_URL}/v1/supply-chain/update-supplier-address`,
                {
                    sequence_id: sequence_id,
                    action,
                    address,
                    address_id: id
    
                },
                { headers: HEADERS }
            );
            if(response.data.meta.success){
                fetchSupplierDetails();
            }else{
                toast.error(response.data.meta.message)
            }
        } catch (error) {
            
        }
       

    }
    const handleAddAddress = () => {
        setAddresses((prev) => [...prev, '']);
    };

    const handleEditAddress = (index, newAddressValue) => {
        const updatedAddresses = [...addresses];
        updatedAddresses[index] = {
            ...updatedAddresses[index], // Preserve the existing object properties (like _id)
            address: newAddressValue, // Update only the address field
        };
        setAddresses(updatedAddresses);
    };

    const handleDeleteAddress = (index,address) => {
        if(address._id){
            updateAddress('delete', address.address, address._id)
        }else{
            const updatedAddresses = [...addresses];
            updatedAddresses.splice(index, 1);
            setAddresses(updatedAddresses);
        }
      
    };
    const handleSaveAddress = (address) => {
        if (address._id) {
            updateAddress('replace', address.address, address._id)
            // console.log('edit', address.address, address._id)
            // onAddressSave('edit',addressId.address,addressId._id,)
        } else {
            updateAddress('add', address.address, '')
            console.log('save')
           
        }

        setEditingIndex(null);
    };
    const handleEditClick = (index) => {
        setEditingIndex(index);
    };



    const handleAddAddressClick = () => {
        handleAddAddress();
        setEditingIndex(addresses.length); // Set editingIndex to the newly added address
    };

    return (
        <div className='supplier-page-view'>
            <div className='supplier-page-top-bar'>
                <div className='create-po-title'>
                    <img
                        style={{ cursor: 'pointer' }}
                        src={`${ASSET_PREFIX_URL}template-back-btn.svg`}
                        alt=""
                        width='24px'
                        height='24px'
                        onClick={handleBack}
                    />
                    <div>
                        Supplier details / <span style={{ color: '#610bee' }}>{sequence_id}</span>
                    </div>
                </div>
            </div>
            <div className='supplier-page-bottom-page'>
                <div className='supplier-page-default-fields'>
                    {renderFields({ ...data.default_fields_data, ...data.custom_fields_data })}
                    <div className='address-field-container'>
                        <span>Address</span>
                        {addresses.map((address, index) => (
                            <div key={index} className='address-field'>
                                <textarea
                                    className='supply-textarea'
                                    placeholder='Enter address'
                                    value={address.address}
                                    onChange={(e) => handleEditAddress(index, e.target.value, address._id)}
                                    disabled={editingIndex !== index}
                                />

                                {editingIndex === index ? (
                                    <img
                                        onClick={() => handleSaveAddress(address)}
                                        src={`${ASSET_PREFIX_URL}save-details.png`}
                                        alt="save"
                                        width='24px'
                                        height='24px'
                                        style={{ cursor: 'pointer' }}
                                    />
                                ) : (
                                    <img
                                        onClick={() => handleEditClick(index)}
                                        src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                        alt="Edit"
                                        width="24px"
                                        height="24px"
                                        style={{ cursor: 'pointer' }}
                                    />
                                )}

                                <img
                                    onClick={() => handleDeleteAddress(index,address)}
                                    src={`${ASSET_PREFIX_URL}attri-delete.png`}
                                    alt="delete"
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        ))}
                        <button onClick={handleAddAddressClick} className="add-alternate-address-btn">
                            Add more Address
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SupplierPageView;
