import React from 'react'
import NameProfile from '../Common/CommonJsx/NameProfile'
import MemberDropdown from '../Common/MemberDropdown'
import { ASSET_PREFIX_URL } from '../../constants/config'
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import AttachmentsFunction from '../Common/CommonJsx/AttachmentsFunction';
import Select from "react-select";

function DepartmentFormComponent({ handleSubmit, handleCreate, label, fieldErrors, handleFieldChange, handleFileInputChange,
    handleSingleSelectChange, handleMultiSelectChange, formData, type, onClose, fileSizeError, uploadError, owner, handleFileDrop,

    handleDragOver, isLoading,
    setUploadedFiles,
    uploadedFiles,
    handlePersonSelectChange,
    inputRef,
}) {
    return (
        <>
            <div onSubmit={handleSubmit} className="form-grid" style={{ marginTop: '20px' }}>
                {label.map((field, index) => (
                    <div
                        key={field}
                        className={
                            field.inputType === "attachment"
                                ? "full-width-item"
                                : "grid-item"
                        }
                        style={
                            field.inputType === "comments" ? { display: "none" } : null
                        }
                    >
                        {field.inputType === 'attachment' && <span>{field.fieldName}</span>}
                        {field.inputType !== 'attachment' && <span>{field.fieldName}{field.is_mandatory === true ? '*' : ''}</span>}
                        {/* <span>{field.fieldName}*:</span> */}
                        {field.fieldName === "Owner" ? (
                            <div style={{width:'360px'}}>
                                <Select
                                    placeholder={field.placeholder}
                                    isDisabled={true}
                                    value={{
                                        label: (
                                            <div
                                                className="member-option"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                }}
                                            >

                                                <NameProfile userName={owner.name} width='26px' memberPhoto={owner.photo} fontweight='500' />



                                                {owner.name}
                                                <span className="you-text">Me</span>
                                            </div>
                                        ),
                                    }}
                                />
                                {/* Render any error messages for the Owner field */}
                            </div>
                        ) : field.inputType === "person" ? (
                            <div style={{width:'360px'}}>
                                <MemberDropdown field={{ 
                                    fieldName: field.fieldName,
                                    placeholder: field.placeholder,
                                    isMulti: field.fieldName === 'Watchers' ? true : false

                                }}  handlePersonSelectChange={handlePersonSelectChange}
                                // allMembers={selectMember} setAllMembers={setSelectMember}
                                />
                                {fieldErrors[field.fieldName] && (
                                    <span className="error-message" style={{ color: "red" }}>
                                        <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                        {fieldErrors[field.fieldName]}
                                    </span>
                                )}
                                {/* Render any error messages for the Owner field */}
                            </div>
                        ) : field.inputType === "text" ||

                            field.inputType === "team" ? (
                            <>
                                <div className="type-texts">
                                    <input
                                        type="text"
                                        placeholder={field.placeholder}
                                        value={formData[field.fieldName] || ""}
                                        onChange={(e) =>
                                            handleFieldChange(field.fieldName, e.target.value)
                                        }
                                        disabled={
                                            field.inputType === "date" &&
                                            field.label === "CreatedOn"
                                        }
                                    />
                                </div>
                                {fieldErrors[field.fieldName] && (
                                    <span className="error-message" style={{ color: "red" }}>
                                        <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                        {fieldErrors[field.fieldName]}
                                    </span>
                                )}
                            </>
                        ) : field.inputType === "paragraph" ? (
                            <>
                                <div className="type-texts">
                                    <textarea
                                        type="text"
                                        placeholder={field.placeholder}
                                        value={formData[field.fieldName] || ""}
                                        onChange={(e) =>
                                            handleFieldChange(field.fieldName, e.target.value)
                                        }
                                        disabled={
                                            field.inputType === "date" &&
                                            field.label === "CreatedOn"
                                        }
                                    />
                                </div>
                                {fieldErrors[field.fieldName] && (
                                    <span className="error-message" style={{ color: "red" }}>
                                        <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                        {fieldErrors[field.fieldName]}
                                    </span>
                                )}
                            </>
                        ) : field.inputType === "single_select" ? (
                            <>
                            <div style={{width:'360px'}}>
                            <Select
                                    placeholder={field.placeholder}
                                    options={field.options.map((opt) => ({
                                        value: opt,
                                        label: opt,
                                    }))}
                                    onChange={(selectedOption) =>
                                        handleSingleSelectChange(
                                            field.fieldName,
                                            selectedOption.value
                                        )
                                    }
                                    value={
                                        formData[field.fieldName]
                                            ? {
                                                value: formData[field.fieldName],
                                                label: formData[field.fieldName],
                                            }
                                            : null
                                    }
                                />
                                {fieldErrors[field.fieldName] && (
                                    <span className="error-message" style={{ color: "red" }}>
                                        <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                        {fieldErrors[field.fieldName]}
                                    </span>
                                )}
                            </div>
                               
                            </>
                        ) : field.inputType === "multi_select" ? (
                            <div style={{width:'360px'}}>
                                <Select
                                    placeholder={field.placeholder}
                                    options={field.options.map((opt) => ({
                                        value: opt,
                                        label: opt,
                                    }))}
                                    isMulti
                                    onChange={(selectedOptions) =>
                                        handleMultiSelectChange(field.fieldName, selectedOptions)
                                    }
                                />
                                {fieldErrors[field.fieldName] && (
                                    <span className="error-message" style={{ color: "red" }}>
                                        <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                        {fieldErrors[field.fieldName]}
                                    </span>
                                )}
                            </div>
                        ) : field.inputType === "number" ? (
                            <>
                                <div className="type-texts">
                                    <input
                                        type="number"
                                        placeholder={field.placeholder}
                                        value={formData[field.fieldName] || ""}
                                        onChange={(e) =>
                                            handleFieldChange(field.fieldName, e.target.value)
                                        }
                                        disabled={
                                            field.inputType === "date" &&
                                            field.label === "CreatedOn"
                                        }
                                    />
                                </div>
                                {fieldErrors[field.fieldName] && (
                                    <span className="error-message" style={{ color: "red" }}>
                                        <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                        {fieldErrors[field.fieldName]}
                                    </span>
                                )}
                            </>
                        ) : field.inputType === "date" ? (
                            <>
                                <div className="type-texts">
                                    <input
                                        type="date"
                                        placeholder={field.placeholder}
                                        value={formData[field.fieldName] || ""}
                                        // value={formData[field.fieldName] ? formatDate(formData[field.fieldName]) :  ''}
                                        min={new Date().toISOString().split("T")[0]}
                                        onChange={(e) =>
                                            handleFieldChange(field.fieldName, e.target.value)
                                        }
                                    />
                                </div>
                                {fieldErrors[field.fieldName] && (
                                    <span className="error-message" style={{ color: "red" }}>
                                        <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                        {fieldErrors[field.fieldName]}
                                    </span>
                                )}
                            </>
                        ) : field.inputType === "date_range" ? (
                            <>
                                <div className="type-texts">
                                    <div>
                                        <span>Start Date</span>
                                        <input
                                            placeholder={field.placeholder}
                                            type="date"
                                            value={formData[field.fieldName]?.start || ""}
                                            min={new Date().toISOString().split("T")[0]}
                                            onChange={(e) =>
                                                handleFieldChange(field.fieldName, {
                                                    ...formData[field.fieldName],
                                                    start: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div>
                                        <span>End Date</span>
                                        <input
                                            type="date"
                                            value={formData[field.fieldName]?.end || ""}
                                            min={formData[field.fieldName]?.start}
                                            onChange={(e) =>
                                                handleFieldChange(field.fieldName, {
                                                    ...formData[field.fieldName],
                                                    end: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                {/* <RangePicker
                value={dateRange}
                onChange={handleDateChange}
                style={{ width: 370 }}
                allowClear={false}
                disabledDate={disabledDate}
              /> */}
                                {fieldErrors[field.fieldName] && (
                                    <span className="error-message" style={{ color: "red" }}>
                                        <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                        {fieldErrors[field.fieldName]}
                                    </span>
                                )}
                            </>
                        ) : field.inputType === "link" ? (
                            <>
                                <div className="type-texts">
                                    <input
                                        type="url"
                                        placeholder={field.placeholder}
                                        value={formData[field.fieldName] || ""}
                                        onChange={(e) =>
                                            handleFieldChange(field.fieldName, e.target.value)
                                        }
                                        style={{ width: "100%" }}
                                    />
                                </div>
                                {fieldErrors[field.fieldName] && (
                                    <span className="error-message" style={{ color: "red" }}>
                                        <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                        {fieldErrors[field.fieldName]}
                                    </span>
                                )}
                            </>
                        ) : field.inputType === "attachment" ? (
                            <div>
                                <input
                                    type="file"
                                    onChange={(e) => handleFileInputChange(e)}
                                    className="full-width-input"
                                    ref={inputRef}
                                    style={{ display: "none" }}
                                />

                                <div
                                    className="filedropzone"
                                    onDrop={handleFileDrop}
                                    onDragOver={handleDragOver}
                                    style={{width:'98%'}}
                                // onDragLeave={handleDragLeave}
                                >
                                    <img src={`${ASSET_PREFIX_URL}uploading-icon.svg`} alt="" />
                                    {isLoading ? (
                                        <p>Uploading ....</p>
                                    ) : (
                                        <p>
                                            Drop files to attach or
                                            <span onClick={() => inputRef.current.click()}>
                                                {" "}
                                                Browse
                                            </span>
                                        </p>
                                    )}
                                </div>

                                {fieldErrors[field.fieldName] && (
                                    <span className="error-message">
                                        <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                        {fieldErrors[field.fieldName]}
                                    </span>
                                )}

                                <AttachmentsFunction uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} type='form-upload' />

                                {uploadError && <div className="error">{uploadError}</div>}
                                {fileSizeError && (
                                    <div className="error">
                                        <span
                                            className="error-message"
                                            style={{ color: "#FF9845" }}
                                        >
                                            <ErrorOutlineOutlinedIcon /> {fileSizeError}
                                        </span>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <>
                                <div className="type-texts">
                                    <input
                                        type={type[index]}
                                        value={formData[type[index]] || ""}
                                        onChange={(e) =>
                                            handleFieldChange(type[index], e.target.value)
                                        }
                                        disabled={type[index] === "date" && field === "CreatedOn"}
                                    />
                                </div>

                                {fieldErrors[field.fieldName] && (
                                    <span className="error-message" style={{ color: "red" }}>
                                        <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                        {fieldErrors[field.fieldName]}
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                ))}
            </div>
            <div className='dept-ticket-btns'>
                <button className="dept-form-submit" onClick={handleCreate}>
                    Create ticket
                </button>

                <button className="dept-form-cancel" onClick={onClose}>
                    Go Back
                </button>
            </div>
        </>
    )
}

export default DepartmentFormComponent