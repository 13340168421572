import React, { useState } from 'react'
import { ASSET_PREFIX_URL, PHOTO_LINK } from '../../../constants/config';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

function AttachmentsFunction({ uploadedFiles, setUploadedFiles, type, uploadType }) {

  const [fileLoadingStates, setFileLoadingStates] = useState([]);
  const getFileIcon = (fileName, file) => {
    console.log(file);
    const extension = file.name.split(".").pop().toLowerCase();

    if (extension === "pdf") {
      return (
        <div
          style={{ overflow: "hidden", border: "none" }}
          className="extension-type-div"
        >
        {!uploadType ? (
            <iframe
              src={`${PHOTO_LINK}${fileName}`}
              title="PDF Preview"
              width="75px"
              height="75px"
              style={{ overflowY: 'hidden', border: 'none', padding: '0px' }}
              scrolling="no"
            />
          ) : (
            file.key ? (
              <iframe
                src={file.key}
                title="File Preview"
                width="75px"
                height="75px"
                style={{ overflowY: 'hidden', border: 'none', padding: '0px' }}
                scrolling="no"
              />
            ) : (
              <img
                src={`${ASSET_PREFIX_URL}pdfFile.png`}
                alt="File Preview"
                width="75px"
                height="75px"
              />
            )
          )}

        </div>
      );
    } else if (extension === "xls" || extension === "xlsx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}xlsFile.png`}
          alt="Excel"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "xml") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}xmlFile.png`}
          alt="XML"
          width="75px"
          height="75px"
        />
      );
    } else if (
      extension === "jpg" ||
      extension === "png" ||
      extension === "jpeg" ||
      extension === "svg"
    ) {
      return <img src={uploadType ? (
        (file.key ? file.key : `${ASSET_PREFIX_URL}photoFile.png`)


      ) : `${PHOTO_LINK}${fileName}`} alt="" className="png-img-div" />;

    } else if (extension === "pptx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}pptxFile.png`}
          alt="ppt"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "csv") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}csvFile.png`}
          alt="csv"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "docx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}wordFile.png`}
          alt="csv"
          width="75px"
          height="75px"
        />
      );
    } else {
      return (
        <img
          src={`${ASSET_PREFIX_URL}filesImage.png`}
          alt="File"
          width="75px"
          height="75px"
        />
      );
    }
  };

  const handleDelete = (index, uploadedFiles, fileLoadingStates, setUploadedFiles, setFileLoadingStates) => {
    const newFiles = [...uploadedFiles];
    const newLoadingStates = [...fileLoadingStates];
    newFiles.splice(index, 1);
    newLoadingStates.splice(index, 1);
    setUploadedFiles(newFiles);
    setFileLoadingStates(newLoadingStates);
  };
  return (
    <div className="uploaded-files-view">
      {uploadedFiles.map((file, index) => (
        <div key={index} className="uploaded-file-view">
          <div className="file-details-view">
            <div className="file-image-preview">
              {getFileIcon(file.key, file)}
            </div>


            <div className="upld-file-details-view">
              <span title={file.name} className="file-view-name">
                {file.name.length > 20
                  ? file.name.substring(0, 15) + "..."
                  : file.name}
              </span>
            </div>

            <div className="file-details-view-hover">
              <a href={uploadType ? (file.drive_file_id ? `https://drive.google.com/file/d/${file.drive_file_id}/preview` : file.key) : `${PHOTO_LINK}${file.key}`} target="_blank">
                <div className="file-box-link">
                  <span
                    title={file.name}
                    className="file-view-name-view"
                  >
                    {file.name.length > 20
                      ? file.name.substring(0, 18) + "..."
                      : file.name}
                  </span>
                </div>
              </a>

              <div className="file-delete">
                <DeleteOutlineOutlinedIcon
                  onClick={() => handleDelete(index, uploadedFiles, fileLoadingStates, setUploadedFiles, setFileLoadingStates)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default AttachmentsFunction