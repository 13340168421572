import React, { useState, useRef, useEffect } from 'react';
import "./TemplateFields.css";
import Select from "react-select";
import axios from "axios";
import { BASE_URL, PHOTO_LINK, ASSET_PREFIX_URL } from "../../constants/config";
import Cookies from "js-cookie";
import Dots from '../Common/DotsDiv/Dots';
import JoditEditor from 'jodit-react';

import PoDepartment from '../PopUpsFolder/PoDepartment';
import NameProfile from '../Common/CommonJsx/NameProfile';

function PoTableCustomFields({ onopen, tableFields, departmentList, setApproverMember,
    setTermsAndCondText, setSelectedApprover, setEditorContent,
    editorContent, setSaveButton, termsAndCondText, approvarMember
}) {
    console.log(approvarMember)
    const [memberDetails, setMemberDetails] = useState([]);
    const [joditValue, setJoditValue] = useState(termsAndCondText);
    const [openDepartments, setOpenDepartements] = useState(false);
    const [approvalMember, setApprovalMember] = useState(approvarMember);

    const editorRef = useRef(null);

    const handleOpenDepartments = () => {
        setOpenDepartements(!openDepartments);
    };

    const fetchMembers = async () => {
        try {
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };

            const response = await axios.get(
                `${BASE_URL}/v1/ec-template/get-members-hierarchy`,
                { headers }
            );

            setMemberDetails(response.data.data);
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };

    const handleApproverChange = (selectedOption) => {
        console.log(selectedOption)
        const selectedMember = memberDetails.find(member => member._id === selectedOption.value);
        const approverDetails = {
            value: {
                member_id: selectedOption.value,
                fullName: selectedOption.label.props.children[1],
                photo: selectedOption.label.props.children[0].props.memberPhoto || '',
                status: ""
            },

            is_default: true,
            is_default_template: true,
            inputType: 'person',
            fieldName: 'select approver',
            options: [],
            template_type: 'approvermember',
            order: 1  // Adjust the order as needed
        };
        setSaveButton(true)
        setApprovalMember(approverDetails.value)
        setApproverMember(approverDetails.value)
        console.log(approverDetails.value)
        setSelectedApprover(approverDetails);
    };

    const handleEditorChange = (newContent) => {
        setJoditValue(newContent)
        setTermsAndCondText(newContent)
        setSaveButton(true)
        // console.log(editorText)

    };


    const displayedFields = tableFields && tableFields.length < 7 ? tableFields : tableFields.slice(0, 6);
    const displayedDepartments = departmentList.length < 5 ? departmentList : departmentList.slice(0, 5);
    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            border: "1px solid #edf2f7",
            boxShadow: "none",
            color: 'black',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        optionLabel: {
            display: "flex",
            alignItems: "center",
        },
        option: (provided, state) => ({
            ...provided,
            background: "white",
            color: "black",
            cursor: "pointer",
            ":hover": {
                background: "#610BEE",
                color: "white",
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "black",
            padding: "5px 10px",
            borderRadius: "4px",
            fontSize: "14px",
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: "14px",
            color: "black",
        }),
        menu: (provided) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            color: "black",
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: "white",
        }),
    };

    return (
        <>
            <div className='po-template-field-box'>
                <div className="part-num-bottom-totype">
                    <img src={`${ASSET_PREFIX_URL}member-icon.png`} alt='' />
                    <span>Add 1 approver</span>
                </div>
                <div className='po-template-custom-box'>
                    <div className="part-num-bottom-box">
                        <Dots />
                    </div>
                    <div className='po-template-custom-inputs'>
                        <Select
                            styles={customStyles}
                            onFocus={fetchMembers}
                            options={memberDetails.map((member) => ({
                                value: member.entity_id?._id, // Use full name as the value
                                label: (
                                    <div
                                        className="member-option"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <NameProfile userName={member.entity_id?.fullName} width='26px' memberPhoto={member.entity_id?.photo} fontweight='500' />
                                        {member.entity_id?.fullName}
                                    </div>
                                ),
                            }))}
                            value={
                                approvalMember && approvalMember.member_id ? {
                                    label: (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                            }}
                                        >
                                            {approvalMember.photo !== '' ? (
                                                <img
                                                    src={PHOTO_LINK + approvalMember.photo}
                                                    alt="options"
                                                    className="owner-image"
                                                />
                                            ) : (
                                                <div className="owner-image-span">
                                                    <NameProfile userName={approvalMember.fullName} />
                                                </div>
                                            )}
                                            {approvalMember.fullName && (approvalMember.fullName.length < 15 ? approvalMember.fullName : approvalMember.fullName.slice(0, 15) + '...')}
                                        </div>
                                    ),
                                } : null
                            }
                            placeholder="Select approver"
                            onChange={handleApproverChange}
                        />
                    </div>
                </div>
            </div>
            <div className='po-template-field-box'>
                <div className="part-num-bottom-totype">
                    <img src={`${ASSET_PREFIX_URL}part-num-alpha.png`} alt='' />
                    <span>Add or remove columns for item table</span>
                </div>
                <div className='po-template-custom-box'>
                    <div className="part-num-bottom-box">
                        <Dots />
                    </div>
                    <div className='po-template-custom-inputs'>
                        {displayedFields.map((item) => (
                            <div className='po-template-custom-fieldnames' key={item.id}>
                                <span>{item.fieldName}</span>
                            </div>
                        ))}
                        {tableFields.length > 6 ? (
                            <button className="view-all-btn" onClick={onopen}>
                                view/add more({tableFields.length - 6})
                            </button>
                        ) : (
                            <img
                                src={`${ASSET_PREFIX_URL}attribute-addmore.png`}
                                alt='add'
                                width='24px'
                                height='24px'
                                style={{ cursor: 'pointer' }}
                                onClick={onopen}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className='po-template-field-box'>
                <div className="part-num-bottom-totype">
                    <img src={`${ASSET_PREFIX_URL}programs.svg`} alt='' />
                    <span>Department</span>
                </div>
                <div className='po-template-custom-box'>
                    <div className="part-num-bottom-box">
                        <Dots />
                    </div>
                    <div className='po-template-custom-inputs'>
                        {displayedDepartments.map((item) => (
                            <div className='po-template-custom-fieldnames' key={item.id}>
                                <span>{item.unique_initial},{item.department_name}</span>
                            </div>
                        ))}
                        {departmentList.length > 6 ? (
                            <button className="view-all-btn" onClick={handleOpenDepartments}>
                                view more({departmentList.length - 6})
                            </button>
                        ) : (
                            <img
                                src={`${ASSET_PREFIX_URL}attribute-addmore.png`}
                                alt='add'
                                width='24px'
                                height='24px'
                                style={{ cursor: 'pointer' }}
                                onClick={handleOpenDepartments}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className='po-template-field-box'>
                <div className="part-num-bottom-totype">
                    <img src={`${ASSET_PREFIX_URL}t%26cimg.png`} alt='' />
                    <span>Terms & Condition</span>
                </div>
                <div className='po-template-tandc-box'>
                    <div className="part-num-bottom-box">
                        <Dots />
                    </div>
                    <div className='po-template-custom-editor'>
                        <JoditEditor
                            ref={editorRef}
                            value={joditValue}
                            onChange={handleEditorChange}
                        />
                    </div>
                </div>
            </div>
            {openDepartments && <PoDepartment departments={departmentList} handleClose={handleOpenDepartments} />}
        </>
    );
}

export default PoTableCustomFields;
