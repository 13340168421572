import React, { useState, useEffect } from "react";
import "./DepartmentForm.css";
import axios from "axios";


import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import {
  BUCKET,
  BASE_URL,
  TKTVIEW,

  CATEGORY_UPLOAD_TICKETS
} from "./../../constants/config";
import {
  simpleUpload,
  multiUpload,
} from "./../../constants/helper";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import DepartmentFormComponent from "./DepartmentFormComponent";



function DepartmentForm({ onClose, departmentId, fromDepartment }) {
  const [formData, setFormData] = useState({});

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [fileFormat, setFileFormat] = useState('');

  const [deptName, setDeptName] = useState('')
  const [label, setLabel] = useState([]);
  const [type, setType] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [templateId, setTemplateId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileSizeError, setFileSizeError] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [owner, setOwner] = useState({});

  const nav = useNavigate();


  useEffect(() => {
    fetchTicketDetails();
  }, []);

  const { department } = useParams();
  const { route } = useParams()
  const fetchTicketDetails = async () => {
    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${BASE_URL}/v1/depart/get-tempalteId/${departmentId}`,
        { headers: headers }
      );
      if (!response) {
        // Display an alert and close the component
        // alert('No fields found. Unable to create ticket.');
        onClose(); // Assuming onClose is a function to close the component
        return;
      }
      // console.log(response.data.data.fields.length)
      setOwner(response.data.data.profile_details);
      handleFieldChange("Owner", response.data.data.profile_details._id);
      setTemplateId(response.data.data.template_id);
      setDeptName(response.data.data.departmentNames);

      const filteredFields = response.data.data.fields
      .filter(
        (field) =>
          field.is_default_template === true ||
          field.is_default_template === undefined
      )
      .sort((a, b) => (a.field?.order || 0) - (b.field?.order || 0)); // Accessing nested order property
    
    
      
      setLabel(filteredFields);
      // console.log(response.data.data.fields)
      setType(response.data.data.fields.map((item) => item.fieldName));
    } catch (error) {
      toast.info(
        `please create a template to create a ticket for ${department}`
      );
      onClose();
      console.error("Error fetching options data:", error);
    }
  };
  const getFileFormat = (fileName) => {
    const parts = fileName.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1].toUpperCase();
    }
    return "Unknown";
  };

  let inputRef = React.createRef();

  const handleFieldChange = (fieldName, value) => {
    console.log(fieldName, value)
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleFileDrop = async (event) => {
    event.preventDefault();

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      handleFile(file);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    handleFile(file);
  };

  const handleFile = async (file) => {
    setFileSizeError("");
    setUploadError("");
    const fileSizeMB = file.size / (1024 * 1024); // size in MB
    if (fileSizeMB > 2) {
      setFileSizeError("File size cannot be more than 2 MB"); // Notify the user
      return; // Exit the function
    }
    //fetch pre signed URL
    setIsLoading(true);
    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      const preSignedURL = await axios.post(
        `${BASE_URL}/v1/member/get-presigned-url`,
        { bucket_name: BUCKET, file: file.name, category: CATEGORY_UPLOAD_TICKETS, filesize: fileSizeMB },
        { headers: headers }
      );
      if (
        preSignedURL.data.meta.code === 200 &&
        preSignedURL.data.meta.message === "SUCCESS" &&
        preSignedURL.data.data.url
      ) {
        if (preSignedURL.data.data.is_mutipart) {
          await multiUpload(preSignedURL.data.data, file, headers);
        } else {
          await simpleUpload(preSignedURL.data.data, file);
        }
        // file.name = preSignedURL.data.data.file_name;
        handleFieldChange("file", file.name);
        setFileFormat(getFileFormat(file.name));
        setUploadedFiles((prevUploadedFiles) => [
          ...prevUploadedFiles,
          { name: file.name, key: preSignedURL.data.data.key },
        ]);
      } else {
        alert("Error generating signed URL");
      }
    } catch (e) {
      console.log(e);
      setUploadError("Error in uploading file");
    } finally {
      // Set isLoading back to false after upload (whether success or failure)
      setIsLoading(false);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();

  };




  const handleSubmit = (event) => {
    event.preventDefault();

    Object.entries(formData).map(([type, value]) => ({
      type,
      value,
    }));
  };

  const handleCreate = async (event) => {
    event.preventDefault();

    const requiredFields = label.filter(
      (field) =>
        (field.is_mandatory || field.is_default) &&
        field.inputType !== "comments" &&
        field.fieldName !== "Owner"
    );
    const errors = {};

    requiredFields.forEach((field) => {
      console.log(formData[field.fieldName]);
      if (field.inputType !== "attachment" && !formData[field.fieldName]) {
        errors[field.fieldName] = "This field is required.";
      }
    });


    setFieldErrors(errors);
    console.log(errors)
    if (Object.keys(errors).length === 0) {

      const ticketData = {};
      const defaultFieldsData = {};

      label.forEach((field, index) => {
        let fieldName = field.fieldName.replace(/\s+/g, "_").toLowerCase();
        if (field.inputType === "attachment") {
          defaultFieldsData[fieldName.toString()] = {
            value: uploadedFiles.map((file) => {
              return { name: file.name, key: file.key };
            }),
            form_field_id: field._id
          };
        } else if (field.inputType === "person") {




          if (field.is_default) {
            defaultFieldsData[fieldName.toString()] = {
              value: field.fieldName === "Owner" ? {
                photo: owner.photo,
                member_id: owner.id,
                fullName: owner.name,
              } : formData[field.fieldName],
              form_field_id: field._id
            };
          } else {
            ticketData[fieldName.toString()] = {
              value: formData[field.fieldName],
              form_field_id: field._id
            };
          }

        } else if (field) {
          const fieldData = {
            value: formData[field.fieldName],
            form_field_id: field._id
            // is_default: field.is_default,
            // inputType: field.inputType,
            // fieldName: field.fieldName,
            // option: field.options,
            // order: index + 1,
          };
          if (field.is_default) {
            defaultFieldsData[fieldName.toString()] = fieldData;
          } else {
            ticketData[fieldName.toString()] = fieldData;
          }
        } else {
          ticketData[fieldName.toString()] = formData[field.fieldName];
        }
      });

      // Convert to JSON string
      const hasTicketData = Object.keys(ticketData).length > 0;
      const jsonData = {
        default_fields_data: {
          ...defaultFieldsData},
        custom_fields_data: hasTicketData ? ticketData : {},
      };

      // You can now send the jsonData to your API or perform other actions
      // Example API call using axios
      try {
        const headers = {
          "x-auth-token": localStorage.getItem("token"),
        };

        const response = await axios.post(
          `${BASE_URL}/v1/ticket/create-ticket`,
          {
            data: jsonData,
            department_id: departmentId,
            department_name: deptName,
            template_id: templateId,
          },
          { headers: headers }
        );

        // console.log(notify)
        nav(TKTVIEW(route, response.data.data.departmentTicket.sequence_id));

        // window.location.reload()
      } catch (error) {
        console.error("Error fetching options data:", error);
      }
      console.log(jsonData)
      // nav(`/department-ticket/${deptName}/${departmentId}`);
    }
  };

  const handleSingleSelectChange = (fieldName, value) => {
    handleFieldChange(fieldName, value);
  };
  const handlePersonSelectChange = (fieldName, value) => {
    console.log(fieldName, value)
    if (fieldName === "Watchers") {

      const formattedValue = value.map(item => ({
        member_id: item.value,
        photo: item.photo ? item.photo : '',
        fullName: item.fullName
      }));
      handleFieldChange(fieldName, formattedValue);
    } else {

      const formattedValue = {
        member_id: value.value,
        photo: value.photo ? value.photo : '',
        fullName: value.fullName
      };
      handleFieldChange(fieldName, formattedValue);
    }
  };




  const handleMultiSelectChange = (fieldName, selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    handleFieldChange(fieldName, selectedValues);
  };



  return (
    <>
      {fromDepartment ? 
      <div className="ec-form-page">
        <div className="ec-ticket-home" >
          <div className="dept-ticket-top">
            <div className="dept-ticket-head">
              <span>Create new ticket</span>
              <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />
            </div>
          </div>
          <DepartmentFormComponent
            handleSubmit={handleSubmit}
            handleCreate={handleCreate}
            label={label}
            fieldErrors={fieldErrors}
            handleFieldChange={handleFieldChange}
            handleFileInputChange={handleFileInputChange}
            handleSingleSelectChange={handleSingleSelectChange}
            handleMultiSelectChange={handleMultiSelectChange}
            formData={formData}
            onClose={onClose}
            type={type}
            fileSizeError={fileSizeError}
            uploadError={uploadError}
            setUploadedFiles={setUploadedFiles}
            uploadedFiles={uploadedFiles}
            handlePersonSelectChange={handlePersonSelectChange}
            inputRef={inputRef}
            owner={owner}
            handleFileDrop={handleFileDrop}

            handleDragOver={handleDragOver} isLoading={isLoading}
          />


        </div>
      </div> :
        <DepartmentFormComponent
          handleSubmit={handleSubmit}
          handleCreate={handleCreate}
          label={label}
          fieldErrors={fieldErrors}
          handleFieldChange={handleFieldChange}
          handleFileInputChange={handleFileInputChange}
          handleSingleSelectChange={handleSingleSelectChange}
          handleMultiSelectChange={handleMultiSelectChange}
          formData={formData}
          onClose={onClose}
          type={type}
          fileSizeError={fileSizeError}
          uploadError={uploadError}
          setUploadedFiles={setUploadedFiles}
          uploadedFiles={uploadedFiles}
          handlePersonSelectChange={handlePersonSelectChange}
          inputRef={inputRef}
          owner={owner}
          handleFileDrop={handleFileDrop}

          handleDragOver={handleDragOver} isLoading={isLoading}
        />
      }


    </>

  );
}

export default DepartmentForm;
