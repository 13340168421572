import React, { useEffect, useState } from 'react';
import '../PopUpsFolder/Notification.css'
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton'
import Select from "react-select";
import Cookies from 'js-cookie';
import axios from 'axios';
import { ASSET_PREFIX_URL, BASE_URL, HEADERS, LOCATIONVIEW } from "../../constants/config";
import { useNavigate } from 'react-router-dom';
import NameProfile from '../Common/CommonJsx/NameProfile';
import CreatableSelect from 'react-select/creatable';
import MemberDropdown from '../Common/MemberDropdown';
import { toast } from 'react-toastify';


function CreateLocation({ handleClose,
    warehouseNumber,
}) {

    const nav = useNavigate()
    const [templateId, setTemplateId] = useState("");
    const [warehouseList, setWarehouseList] = useState([]);
    const [fields, setFields] = useState([]);
    const [formData, setFormData] = useState({});

    const [selectedWarehouse, setSelectedWarehouse] = useState(null)
    const [data, setData] = useState({});
    const [FieldErrors, setFieldErrors] = useState({});
    const getLocationFields = async () => {
        try {
            //   setLoading(true)


            // get-asset-fields
            const fields = await axios.get(`${BASE_URL}/v1/location/get-location-fields`, { headers: HEADERS },)

            setFields(fields.data.data.fields)

            setTemplateId(fields.data.data.fields[0].template_id);


            //   setLoading(false)
        } catch (error) {
            console.log(error);
        }
    };
    const getWarehouseChildList = async () => {
        try {
            //   setLoading(true)


            // get-asset-fields
            const fields = await axios.get(`${BASE_URL}/v1/location/get-warehouse-list`, { headers: HEADERS },)

            setWarehouseList(fields.data.data.warehouse_list)
            // setFields(fields.data.data.fields)

            // setTemplateId(fields.data.data.fields[0].template_id);


            //   setLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {

        getLocationFields();

        // Clear the timer on each input change

    }, []);
    const handleWarehouseSelect = (selected) => {
        setSelectedWarehouse(selected.value)
    }






    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: "340px",
            backgroundColor: "transparent",
            border: "1px solid #EDF2F7",
            boxShadow: "none",
        }),

        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),

        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "transparent" : "white",
            color: state.isSelected ? "black" : "inherit",
            cursor: "pointer",
            ":hover": {
                background: "lightgray",
            },
        }),


    };




    const handleCreate = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setFieldErrors(validationErrors);
            return; // Exit early if there are validation errors
        }





        const transformData = (data, isDefault) => {
            return Object.keys(data)
                .filter(key => data[key].is_default === isDefault)
                .reduce((obj, key) => {
                    obj[key] = {
                        value: data[key].value,
                        form_field_id: data[key].form_field_id
                    };
                    return obj;
                }, {});
        };
        
        const defaultData = transformData(data, true);
        const customData = transformData(data, false);
        try {
            const response = await axios.post(
                `${BASE_URL}/v1/location/create-new-location`,
                {



                    warehouse_sequence_id: warehouseNumber ? warehouseNumber : selectedWarehouse,
                    zone_ids: selectedOptions.map(item => item.value),
                    template_id: templateId,
                    location_custom_data: JSON.stringify(customData),
                    location_default_data: JSON.stringify(defaultData)
                },
                { headers: HEADERS }
            );

            if (response.data.meta.success === false) {
                console.error(response.data.meta.message);
                toast.error(response.data.meta.message);
            } else {
                nav(LOCATIONVIEW(response.data.data.new_location))


                handleClose();

            }
        } catch (error) {
            console.error("An error occurred during the request:", error);
            toast.error("An error occurred during the request. Please try again.");
        }


    };





    let filteredFields;
    filteredFields =
        fields.filter(field => field.is_default || field.is_default_template);

    const validateFields = () => {
        const requiredFields = filteredFields.filter(
            (field) =>
                (field.is_mandatory || field.is_default)
        );

        const newErrors = {};

        requiredFields.forEach((field) => {
            const fieldName = field.fieldName.replace(/\s+/g, '_').toLowerCase();
            if (!formData[fieldName]) {
                newErrors[fieldName] = "This field is required.";
            }
        });
        console.log(newErrors)
        setFieldErrors(newErrors);
        return newErrors;
    };


    const handlePersonSelectChange = (fieldName, value) => {
        let fieldValue = {
            photo: value.photo ? value.photo : '',
            member_id: value.value,
            fullName: value.fullName,
        };

        const updatedFormData = { ...formData, [fieldName.replace(/\s+/g, '_').toLowerCase()]: fieldValue };
        setFormData(updatedFormData);
        saveFieldData(fieldName.replace(/\s+/g, '_').toLowerCase(), fieldValue);


    };
    const handleMultiPersonSelectChange = (fieldName, value) => {

        console.log(fieldName)
        const fieldValue = value.map(option => ({
            photo: option.photo?option.photo:'' ,
            member_id: option.value,
            fullName: option.fullName,
        }));

        const transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();

        const updatedFormData = { ...formData, [transformedFieldName]: value };


        setFormData(updatedFormData);
        saveFieldData(transformedFieldName, fieldValue);
      

    };


    const handleMultiSelectChange = (fieldName, value) => {
        let fieldValue = value.map(item => item.label);
        const transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();
        const updatedFormData = { ...formData, [transformedFieldName]: value };
        setFormData(updatedFormData);
        saveFieldData(transformedFieldName, fieldValue);

        // Clear field error
        // setFieldErrors(prevErrors => ({ ...prevErrors, [fieldName]: '' }));
    };

    const handleChange = (fieldName, value, dateType) => {
        const transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();
        let updatedFormData = { ...formData };


        updatedFormData[transformedFieldName] = value;


        setFormData(updatedFormData);
        saveFieldData(transformedFieldName, updatedFormData[transformedFieldName]);

        // Clear field error
        // setFieldErrors(prevErrors => ({ ...prevErrors, [fieldName]: '' }));
    };


    const saveFieldData = (fieldName, value) => {
        const field = filteredFields.find(f => f.fieldName.replace(/\s+/g, '_').toLowerCase() === fieldName);
        if (!field) return;
        let fieldData;
        if (field.inputType === 'single_select') {
            fieldData = {
                value: value.label,
                is_default: field.is_default,
                inputType: field.inputType,
                fieldName: field.fieldName,
                options: field.options,
                order: filteredFields.indexOf(field) + 1,
                form_field_id:field._id
            };
        } else {
            fieldData = {
                value: value,
                is_default: field.is_default,
                inputType: field.inputType,
                fieldName: field.fieldName,
                options: field.options,
                order: filteredFields.indexOf(field) + 1,
                form_field_id:field._id
            };
        }

        setData(prevData => ({
            ...prevData,
            [fieldName]: fieldData
        }));
    };



    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);


    const getZones = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/v1/location/get-zones`, { headers: HEADERS });
            setOptions(response.data.data.zones.map(zone => ({
                value: zone._id,
                label: zone.zone_name
            })));
        } catch (error) {
            console.error("An error occurred during the request:", error);
        }
    };


    const handleAddZones = async (inputValue) => {
        try {
            const response = await axios.post(
                `${BASE_URL}/v1/location/create-zones`,
                { zone_name: inputValue },
                { headers: HEADERS }
            );
            const newZone = {
                value: response.data.data.zone_id,
                label: inputValue,
            };


            setOptions(prevOptions => [...prevOptions, newZone]);
            setSelectedOptions(prevSelected => (prevSelected ? [...prevSelected, newZone] : [newZone]));
        } catch (error) {
            console.error("An error occurred during the request:", error);
        }
    };


    const handleZoneSelection = (selected) => {
        setSelectedOptions(selected);
    };



    return (
        <>

            <div className='create-part-number-popup-page' >
                <div className='create-part-num-div' >
                    <div className='create-part-num-div-top'>
                        <span>Create Location</span>
                        <PopupCloseButton handleClose={handleClose} />
                    </div>


                    <div className='create-part-num-div-form' style={{ overflowY: 'scroll' }}>




                        <div className="create-part-num-div-form-dynamic">

                            {!warehouseNumber && <div className='create-asset-num-div-form-label-input'>
                                <span>Warehouse</span>
                                <Select styles={customStyles} onFocus={getWarehouseChildList} options={warehouseList.map(
                                    (opt) => ({ value: opt.warehouse_sequence_id, label: `${opt.warehouse_sequence_id},(${opt.warehouse_default_data.warehouse_name.value})` })
                                )} onChange={(selected) => handleWarehouseSelect(selected)} />
                            </div>}
                            <div className='create-asset-num-div-form-label-input'>
                                <span>Select zone</span>
                                <CreatableSelect
                                    isMulti
                                    styles={customStyles}
                                    options={options}
                                    value={selectedOptions}
                                    onFocus={getZones}
                                    onChange={handleZoneSelection}
                                    onCreateOption={handleAddZones}
                                    placeholder="Select or create Zones"
                                />
                            </div>
                            {filteredFields.map((field, index) => (
                                <div key={index} className='create-asset-num-div-form-label-input'>
                                    <span>{field.fieldName}</span>
                                    {(field.inputType === 'text') && (
                                        <input
                                            type='text'
                                            // disabled={!inputDisable}
                                            placeholder={field.placeholder || ''}
                                            value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                            onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                        />
                                    )}
                                    {(field.inputType === 'paragraph') && (
                                        <textarea

                                            // disabled={!inputDisable}
                                            placeholder={field.placeholder || ''}
                                            value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                            onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                        />
                                    )}
                                    {(field.inputType === 'link') && (
                                        <input
                                            type='url'
                                            // disabled={!inputDisable}
                                            placeholder={field.placeholder || ''}
                                            value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                            onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                        />
                                    )}
                                    {(field.inputType === 'number') && (
                                        <input
                                            type='number'
                                            // disabled={!inputDisable}
                                            placeholder={field.placeholder || ''}
                                            value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                            onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                        />
                                    )}
                                    {field.inputType === 'date_range' && (
                                        <div className="type-texts" style={{ width: '335px' }}>
                                            <div>
                                                <span>Start Date</span>
                                                <input
                                                    placeholder={field.placeholder}
                                                    type="date"
                                                    value={formData[field.fieldName]?.start || ""}
                                                    min={new Date().toISOString().split("T")[0]}
                                                    onChange={(e) =>
                                                        handleChange(field.fieldName, {
                                                            ...formData[field.fieldName],
                                                            start: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <span>End Date</span>
                                                <input
                                                    type="date"
                                                    value={formData[field.fieldName]?.end || ""}
                                                    min={formData[field.fieldName]?.start}
                                                    onChange={(e) =>
                                                        handleChange(field.fieldName, {
                                                            ...formData[field.fieldName],
                                                            end: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>


                                    )}
                                    {field.inputType === 'single_select' && (
                                        <Select
                                            styles={customStyles}
                                            // isDisabled={!inputDisable || field.fieldName === 'Status'}
                                            options={field.options.map(option => ({ value: option, label: option }))}
                                            value={
                                                formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] ||
                                                (field.fieldName === 'Status' ? { value: 'Created', label: 'Created' } : '')
                                            }
                                            onChange={(selectedOption) => handleChange(field.fieldName, selectedOption)}
                                        />
                                    )}
                                    {field.inputType === 'multi_select' && (
                                        <Select
                                            styles={customStyles}
                                            isMulti
                                            // isDisabled={!inputDisable}
                                            options={field.options.map(option => ({ value: option, label: option }))}
                                            onChange={(selectedOptions) => handleMultiSelectChange(field.fieldName, selectedOptions)}
                                        />
                                    )}
                                    {field.inputType === 'person' && (
                                        <div style={{width: "340px"}}>
                                            <MemberDropdown field={{
                                                fieldName: field.fieldName,
                                                placeholder: field.placeholder,
                                                isMulti: field.inputType === 'multi_person' ? true : false

                                            }}
                                                handlePersonSelectChange={field.inputType !== 'multi_person' ? handlePersonSelectChange:handleMultiPersonSelectChange}
                                            />
                                        </div>

                                    )}

                                    {field.inputType === 'date' && (
                                        <input
                                            type='date'
                                            // disabled={!inputDisable}
                                            min={field.fieldName !== 'PO Date' ? new Date().toISOString().split("T")[0] : ''}
                                            value={formData[field.fieldName.replace(/\s+/g, '_').toLowerCase()] || ''}
                                            onChange={(e) => handleChange(field.fieldName, e.target.value)}
                                        />
                                    )}

                                    {FieldErrors[field.fieldName.replace(/\s+/g, '_').toLowerCase()] && (
                                        <div className="error-message">
                                            {FieldErrors[field.fieldName.replace(/\s+/g, '_').toLowerCase()]}
                                        </div>
                                    )}


                                </div>
                            ))}

                        </div>



                    </div>

                    <div className='create-part-num-div-btns'>
                        <button onClick={handleCreate}>Create</button>
                        <button onClick={handleClose}>Cancel</button>

                    </div>
                </div>
            </div>

        </>

    )
}
export default CreateLocation