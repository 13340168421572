import React from 'react';
import './PoDownload.css';
import { PHOTO_LINK } from '../../constants/config';

function PotitleCard({ orgDetails = {}, selectedAddresses,logo  }) {
  const {  name, addresses= [], cin, tan, pan,gst } = orgDetails;

  return (
    <div className='download-po-title-page'>
      <img
        src={`${PHOTO_LINK}${logo}`}
        alt="Marathon Logo"
        crossOrigin="anonymous"
      />
      <span className='download-po-title-span'>{name}</span>
      <div className='download-po-address-title'>
        <address>
          {selectedAddresses}
        </address>
        <address>
          {cin && `${cin} |`} {gst}
        </address>
        <address>
          {tan && `${tan} |`} {pan}
        </address>
      </div>
      <span className='download-po-title-span-2'>PURCHASE ORDER</span>
    </div>
  );
}

export default PotitleCard;
