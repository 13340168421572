import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ASSET_PREFIX_URL, BASE_URL } from '../../constants/config';
import Select from 'react-select';
import PartSupplierAttributes from './PartSupplierAttributes';

const attributeOptions = [
    { value: 'lead_time', label: 'Lead Time' },
    { value: 'shipping_quantity', label: 'Shipping Quantity' },
];
const initializeRowsWithErrorState = (rows) => {
    return rows.map(row => {
        const newRow = {};
        Object.keys(row).forEach(key => {
            newRow[key] = {
                ...row[key],
                error: '', // Initialize with no error
            };
        });
        return newRow;
    });
};
function PoExcellSheetView({ tableViewData, setEditedTabledData, setDisableUpdate, poType, editable, supplierId }) {
console.log(tableViewData)
const [rows, setRows] = useState([]);
const [suggestions, setSuggestions] = useState([]);
const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
const [totalAmount, setTotalAmount] = useState(0);
const [rowIndexPart, setRowIndexPart] = useState(0);
const [loading, setLoading] = useState(false);
const [currentPage, setCurrentPage] = useState(1);
const [hasMoreSuggestions, setHasMoreSuggestions] = useState(true);
const headers = Object.keys(tableViewData[0] || {});
const [selectedAttribute, setSelectedAttribute] = useState(null);
const [openAttributePopUp, setOpenAttributePopUp] = useState(false);
const [suggestedAttributes, setSuggestedAttributes] = useState({});

useEffect(() => {
    if (tableViewData && tableViewData.length > 0) {
        const initializedRows = initializeRowsWithErrorState(tableViewData);
        setRows(initializedRows);
        calculateTotalAmount(initializedRows);
    }
}, [tableViewData]); // This will only trigger when tableViewData changes


    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newRows = [...rows];

        newRows[index][name] = {
            ...newRows[index][name],
            value: value
        };

        if (name === 'quantity') {
            const quantity = parseFloat(value);
            if (suggestedAttributes?.shipment_quantity && quantity % suggestedAttributes?.shipment_quantity !== 0) {
                newRows[index][name].error = 'Quantity must be a multiple of shipment quantity';
            } else {
                newRows[index][name].error = ''; // Clear error
            }
        }

        setRows(newRows);

        // Other updates
        if (name === 'part_number') {
            fetchSuggestions(value, index, 1);
        }

        if (name === 'total_cost') {
            calculateTotalAmount(newRows);
        }
        setDisableUpdate(true)
    };



    const fetchSuggestions = async (searchValue, rowIndex, page = 1) => {
        setLoading(true);
        try {
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            let response;
            if (poType === 'view') {
                response = await axios.get(`${BASE_URL}/v1/purchase-order/get-part-po`, {
                    params: { search: searchValue, page, size: 6 },
                    headers
                });
                const parts = response.data.data.parts.filter(part => !isPartNumberInTable(part.part_number));
                setSuggestions(prevSuggestions => (page === 1 ? parts : [...prevSuggestions, ...parts]));
                setHasMoreSuggestions(parts.length > 0);
            } else {
                response = await axios.get(`${BASE_URL}/v1/asset/get-asset-in-asset-po`, {
                    params: { search: searchValue, page, size: 5 },
                    headers
                });
                const assets = response.data.data.asset.filter(asset => !isPartNumberInTable(asset.asset_number));
                setSuggestions(prevSuggestions => (page === 1 ? assets : [...prevSuggestions, ...assets]));
                setHasMoreSuggestions(assets.length > 0);
            }

            setActiveSuggestionIndex(rowIndex);
            setCurrentPage(page);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        } finally {
            setLoading(false);
        }
    };

    const loadMoreSuggestions = () => {
        if (!loading && hasMoreSuggestions && activeSuggestionIndex >= 0) {
            // Check if 'part_number' is correctly accessed in your row object
            const searchValue = rows[activeSuggestionIndex]?.part_number?.value || '';
            // if (searchValue) {
            fetchSuggestions(searchValue, activeSuggestionIndex, currentPage + 1);
            // }
        }
    };


    const isPartNumberInTable = (partNumber) => {
        return rows.some(row => row.part_number.value === partNumber);
    };

    const handleSuggestionClick = async (rowIndex, suggestion) => {

        const newRows = [...rows];

        // Update the clicked row with the suggestion's value


        // Update part_number and description fields based on poType
        if (poType === 'view') {
            newRows[rowIndex].part_number = {
                ...newRows[rowIndex].part_number,
                value: suggestion.part_number,
            };
            newRows[rowIndex].description = {
                ...newRows[rowIndex].description,
                value: suggestion.part_title,
            };
        } else {
            newRows[rowIndex].description = {
                ...newRows[rowIndex].description,
                value: suggestion.asset_title,
            };
            newRows[rowIndex].part_number = {
                ...newRows[rowIndex].part_number,
                value: suggestion.asset_number,
            };
        }

        if (poType === 'view') {
            try {

                const headers = {
                    "x-auth-token": localStorage.getItem("token"),
                };
                const response = await axios.get(`${BASE_URL}/v1/purchase-order/get-attribute-supplier`, {
                    params: { supplier_id: supplierId, part_number: newRows[rowIndex].part_number.value, },
                    headers
                });
                console.log(response)
                if (!response.data.data) {
                    setOpenAttributePopUp(!openAttributePopUp)
                    setSuggestedAttributes({ part_number: newRows[rowIndex].part_number.value })
                    setRowIndexPart(rowIndex)
                }
            } catch (error) {
                console.log(error)
            }

        }
        setRows(newRows);
        setSuggestions([]);
        setActiveSuggestionIndex(-1);
    };

    const handleClearRow = (rowIndex) => {

        const newRows = [...rows];
        console.log(newRows)


        newRows[rowIndex].part_number.value = '';
        newRows[rowIndex].description.value = ''; // Clear part number


        setRows(newRows); // Update rows state
    };

    const handleAddRow = () => {
        // Create a new row object with default values from the first object in tableViewData
        const newRow = {};

        // Initialize newRow with values from the first object in tableViewData
        Object.keys(tableViewData[0]).forEach(header => {
            newRow[header] = {
                value: "",
                _id: tableViewData[0][header]._id,
                fieldName: tableViewData[0][header].fieldName,
                template_id: tableViewData[0][header].template_id,
                organization_id: tableViewData[0][header].organization_id,
                inputType: tableViewData[0][header].inputType,
                is_default: tableViewData[0][header].is_default,
                template_type: tableViewData[0][header].template_type,
                is_default_template: tableViewData[0][header].is_default_template,
                order: tableViewData[0][header].order,
                // Add other properties as needed
            };
        });

        // Set the "sr.no" field to the next sequential number
        newRow["sr.no"].value = rows.length + 1;

        // Add the new row to the existing rows
        const updatedRows = [...rows, newRow];


        setRows(updatedRows);
        setDisableUpdate(true)
    };





    const handleDeleteRow = (index) => {
        if (rows.length > 1) {
            const newRows = rows.filter((_, i) => i !== index);
            setRows(newRows);
            calculateTotalAmount(newRows);
        }
        setDisableUpdate(true)
    };

    useEffect(() => {
        // Check if rows and tableViewData are identical
        if (JSON.stringify(rows) === JSON.stringify(tableViewData)) {
            // Reset to original data if no changes detected
            setEditedTabledData(tableViewData);
        } else {
            // If changes detected, update with rows and include `value` and `form_field_id`
            const updatedRows = rows.map(row => {
                const updatedRow = {};
                Object.keys(row).forEach(fieldKey => {
                    updatedRow[fieldKey] = {
                        // ...row[fieldKey],
                        value: row[fieldKey]?.value || "",  // Ensure `value` is included
                        form_field_id: row[fieldKey]?.form_field_id || ""  // Ensure `form_field_id` is included
                    };
                });
                return updatedRow;
            });
            setEditedTabledData(updatedRows);
        }
    }, [rows, tableViewData, setEditedTabledData]);
    

    const calculateTotalAmount = (rowsData) => {
        const total = rowsData.reduce((sum, row) => {
            const totalCost = parseFloat(row['Total cost']) || 0;
            return sum + totalCost;
        }, 0);
        setTotalAmount(total);
    };
    const handleAttribute = async (selected, index) => {

        setSelectedAttribute(selected.label)

        try {
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            const response = await axios.get(`${BASE_URL}/v1/purchase-order/get-attribute-supplier`, {
                params: { supplier_id: supplierId, part_number: index.part_number.value, },
                headers
            });
            console.log(response)
            setSuggestedAttributes(response.data.data ? response.data.data : {})
            setOpenAttributePopUp(!openAttributePopUp)

        } catch (error) {
            console.log(error)
        }
    }
    const handleClosePopup = () => {
        setOpenAttributePopUp(!openAttributePopUp)
    }
    const handleOpenSuggestions = (rowIndex, fieldName, value) => {
        console.log(rowIndex, fieldName, value)
        if (fieldName.
            inputType === 'part_number') {
            fetchSuggestions(value, rowIndex, 1); // Reset suggestions on new part number input
        }
    }

    const getShipmentData = async (partnumber) => {
        // Check if 'rowdata' has the 'quantity' key

        console.log('hi')
        try {
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };

            const response = await axios.get(`${BASE_URL}/v1/purchase-order/get-attribute-supplier`, {
                params: {
                    supplier_id: supplierId, // Ensure supplierId is in scope
                    part_number: partnumber,
                },
                headers,
            });

            console.log('Shipment Data Response:', response);

            // Store the fetched attributes
            setSuggestedAttributes(response.data.data && response.data.data);
        } catch (error) {
            console.error('Error fetching shipment data:', error);
        }

    };
    const isRowEditable = (row) => {
        return row['part_number'].value !== '';
    };
    return (
        <>
            <div className="po-excel-sheet-container">
                <span style={{ fontWeight: '600' }}>Item table</span>
                <table className="po-table" style={styles.poTable}>
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index} style={styles.tableHeader}>
                                    {tableViewData[0][header].fieldName}
                                    <br/>
                                    {tableViewData[0][header].fieldName === "Quantity" && poType === 'view' && (
                                        <span style={{ color: 'red', fontSize: '8px' }}>
                                            (should be multiple of shipment quantity)
                                        </span>
                                    )}
                                    
                                </th>
                            ))}
                              {poType === 'view' && <th style={styles.tableHeader}>Actions</th>}
                            
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {headers.map((header, index) => (
                                    <td key={index} style={styles.tableCell}>
                                        <input

                                            type="text"
                                            // disabled={!editable}
                                            name={header}
                                            value={row[header].value}
                                            onChange={(event) => handleInputChange(rowIndex, event)}
                                            disabled={!editable || (header !== 'part_number' && !row['part_number'].value)} // Disable if not editable or field is not 'part_number' and 'part_number' is empty
                                            style={{
                                                ...styles.input,
                                                borderColor: header === 'quantity'
                                                    ? (row[header].error ? 'red' : '#edf2f7') // Special handling for quantity errors
                                                    : (row[header].value === '' ? 'red' : '#edf2f7') // Handle empty values for other fields
                                            }}
                                            onFocus={() => {
                                                const partNumber = row.part_number.value;
                                                // Check if the poType is 'create' and the current field is 'quantity'
                                                if (poType === 'view' && header.toLowerCase() === 'quantity' && partNumber) {
                                                    // Call the function with partNumber and the entire row as rowdata
                                                    getShipmentData(partNumber);
                                                }
                                            }}
                                            onClick={(e) => handleOpenSuggestions(rowIndex, row[header], e.target.value)}
                                        />
                                        {header === 'part_number' && rowIndex === activeSuggestionIndex && suggestions.length > 0 && (
                                            <div className="suggestions-container">
                                                <div className="suggestions-box" style={styles.suggestionsBox} onScroll={loadMoreSuggestions}>
                                                    {
                                                        suggestions.length < 1 ? (
                                                            <div className="suggestion-item" style={styles.suggestionItem}>No parts!</div>
                                                        ) : (
                                                            suggestions.map((suggestion, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="suggestion-item"
                                                                    style={styles.suggestionItem}
                                                                    onClick={() => handleSuggestionClick(rowIndex, suggestion)}
                                                                >
                                                                    {poType === 'view' ? (
                                                                        <>
                                                                            {suggestion.part_number}, {suggestion.part_title}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {suggestion.asset_number}, {suggestion.asset_title}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            ))
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                ))}
                                {poType=== 'view' &&  <td>
                                    <Select
                                        options={attributeOptions}
                                        placeholder="Select"
                                        onChange={(selected) => handleAttribute(selected, row)}
                                        isDisabled={!row.part_number.value && editable} // Disable if part_number is empty
                                    />
                                </td>}
                               

                                {editable && <td style={styles.tableCell}>
                                    <img
                                        style={styles.icon}
                                        src={`${ASSET_PREFIX_URL}attri-delete.png`}
                                        alt='delete'
                                        onClick={() => handleDeleteRow(rowIndex)}
                                    />
                                </td>}

                            </tr>
                        ))}
                    </tbody>
                </table>
                {editable && <div className="spreadsheet-controls" style={{ marginTop: '10px' }}>
                    <img
                        src={`${ASSET_PREFIX_URL}attribute-addmore.png`}
                        alt='add'
                        width='24px'
                        height='24px'
                        style={{ cursor: 'pointer' }}
                        onClick={handleAddRow}
                    />
                </div>}

            </div>
            {openAttributePopUp && <PartSupplierAttributes handleClearRow={handleClearRow} partNumberRow={rowIndexPart} supplierId={supplierId} onclose={handleClosePopup} type={selectedAttribute} data={suggestedAttributes} />}

        </>

    );
}

const styles = {
    poTable: {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '20px',
        border: '1px solid #ddd',
    },
    tableHeader: {
        padding: '8px',
        border: '1px solid #ddd',
        backgroundColor: '#f0f0f0',
        textAlign: 'left',
    },
    tableCell: {
        padding: '8px',
        border: '1px solid #ddd',
        position: 'relative',
        textAlign: 'left',
    },
    input: {
        width: '90%',
        padding: '8px',
        border: '1px solid #edf2f7',
        borderRadius: '4px',
        background: 'white',
        outline: 'inherit'
    },
    icon: {
        cursor: 'pointer',
    },
    suggestionsBox: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: 'white',
        border: '1px solid #ddd',
        zIndex: 1000,
        maxHeight: '150px',
        overflowY: 'auto',
    },
    suggestionItem: {
        padding: '8px',
        cursor: 'pointer',
        borderBottom: '1px solid #ddd',
    },
};

export default PoExcellSheetView;
