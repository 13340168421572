import React, { useState, useEffect } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import "../DepartmentPages/Department.css";
import BreadcrumbsNav from "../Common/BreadcrumbsNav";
import CreateWorkspacePopUp from "./CreateWorkspacePopUp";
import CustomTabs from "../Common/CustomTabs";
import "./PDM.css";

import {
    ASSET_PREFIX_URL,
    BASE_URL,
} from "../../constants/config";
import { useParams } from 'react-router-dom';
import CreateDesignPopUp from "./CreateDesignPopUp";
import WorkspaceFiles from "./WorkspaceFiles";
import WorkspaceDetails from "./WorkspaceDetails";
import WorkspaceActivityLog from "./WorkspaceActivityLog";
import WorkspaceDriveFiles from "./WorkspaceDriveFiles";


function Workspace() {
    const { workspace_id } = useParams();
    const [currentTab, setCurrentTab] = useState(0);
    const [activityTabDesignId, setActivityTabDesignId] = useState('');
    const [isPopupFormOpen, setIsPopupFormOpen] = useState(false);
    const handleForm = () => {
        setIsPopupFormOpen(!isPopupFormOpen);
    };

    useEffect(() => {
        if (currentTab !== 2) {
            setActivityTabDesignId('');
        }
    }, [currentTab]);
    return (
        <>
        
            <div className='supplier-page-view'>
                <div className='supplier-page-top-bar' style={{ padding: '20px' }}>
                    <div className='create-po-title' style={{ flex: 1 }}>
                        <BreadcrumbsNav
                            links={[
                                { label: "Workspaces", href: "/engineering/workspaces" },
                            ]}
                            current={workspace_id}
                        />
                    </div>
                    <div className="part-number-list-btns" style={{ flex: 1, justifyContent: 'flex-end' }}>
                         <button
                            className="tkt-btn"
                            onClick={handleForm}
                            data-tour="step-8"
                        >
                            <AddOutlinedIcon />
                            New design
                        </button>
                       

                    </div>
                </div>
                 <div className='supplier-page-bottom-page' style={{ padding: '8px' }}>
                    <div className='workspace-detail-tab'>
                        <CustomTabs
                            tabLabels={["Files", "Details", "Activity Log",'Drive files']}
                            currentTab={currentTab}
                            setCurrentTab={setCurrentTab}
                            panels={[
                                () => <WorkspaceFiles
                                
                                    workspace_sequence_id={workspace_id}
                                    openActivityLog={(design_id) => {
                                        setCurrentTab(2); // Switch to Activity Log tab
                                        setActivityTabDesignId(design_id); // Pass params to the log
                                    }}
                                />,
                                () => <WorkspaceDetails workspace_sequence_id={workspace_id} />,
                                () => <WorkspaceActivityLog workspace_sequence_id={workspace_id} design_id={activityTabDesignId} />,
                                () => <WorkspaceDriveFiles workspace_sequence_id={workspace_id} setCurrentTab={setCurrentTab}/>,
                            ]}
                        />
                    </div>
                </div>
                
            </div>
            {
                isPopupFormOpen && <CreateDesignPopUp onClose={handleForm} workspace_sequence_id={workspace_id} setCurrentTab={setCurrentTab}/>
            }
        </>
    );
}
export default Workspace;