import React, { useState } from 'react'
import './Template.css'
import { useParams } from 'react-router-dom';


function TextTemplate({ onUpdate, data, onClose, containsAlphanumeric, fieldNameArray,noFieldNameError,
  startsWithAlphaError,uniqueFieldNameError,setVersionNumber,defaultFieldsArray,uniqueError }) {
    
console.log(data)
console.log(fieldNameArray)
const filteredFieldNameArray = fieldNameArray.filter((item) => item !== data.fieldName);
console.log(filteredFieldNameArray)
  const [is_numeric, setis_numeric] = useState(data.is_numeric);
  const [is_special_character, setis_special_character] = useState(data.is_special_character);
  const [character_limit, setcharacter_limit] = useState(data.character_limit);
  const [is_mandatory, setis_mandatory] = useState(data.is_mandatory);
  const [fieldName, setFieldName] = useState(data.fieldName);
  const [characterLimit, setCharacterLimit] = useState(data.characterLimit);
  const [characterCount, setCharacterCount] = useState(data.characterCount);
  const { templateId } = useParams();
  const [error, setError] = useState('')

  const handleSwitch1 = () => {
    setis_numeric((prevState) => !prevState);
  };
  const handleSwitch2 = () => {
    setis_special_character((prevState) => !prevState);
  };
  const handleSwitch3 = () => {
    setcharacter_limit((prevState) => !prevState);
    setCharacterLimit(!characterLimit);
  };
  const handleFieldNameChange = (event) => {
    setFieldName(event.target.value);
  };
  const handleCharacterCountChange = (event) => {
    setCharacterCount(event.target.value);
  };
  const handleCheckboxChange = (event) => {
    // Update the state with the new value of the checkbox
    setis_mandatory(event.target.checked);
  };
  //  const containsAlphanumeric = /^[a-zA-Z].*\d|\d.*[a-zA-Z]/.test(fieldName);
  // const containsAlphanumeric = containsAlphanumeric.test(fieldName);

  const handleUpdate = () => {
    setVersionNumber=false
    if (!fieldName) {
      setError(noFieldNameError);

    } else if (!containsAlphanumeric.test(fieldName)) {
      setError(startsWithAlphaError);

    } else if (filteredFieldNameArray.includes(fieldName)) {
      setError(uniqueFieldNameError);
    }else if(defaultFieldsArray.includes(fieldName.toLowerCase()) || defaultFieldsArray.includes(fieldName.toUpperCase())){
      setError(uniqueError)
    } else {
      const updatedData = {
        template_id: templateId,
        inputType: 'text',
        is_default: false,
        is_mandatory:is_mandatory?is_mandatory:false,
        fieldName,
        displayType: fieldName,
        // is_numeric:is_numeric?is_numeric:false,
        // is_special_character:is_special_character?is_special_character:false,
        // character_limit:character_limit?character_limit:false,
        // characterCount:characterCount?characterCount:''

      };

      if (character_limit) {
        // Update characterCount only when character_limit is true
        data.characterCount = characterCount;
      }
     
      onUpdate({ ...data, ...updatedData })
      // setIsTextTemplateVisible(false)
      onClose()
    }


  };
  const handleCancel = () => {
    onClose()
  }
  const isDisabled = !containsAlphanumeric.test(fieldName) || !fieldName ||
  (data.fieldName?filteredFieldNameArray.includes(fieldName):fieldNameArray.includes(fieldName))||
  defaultFieldsArray.includes(fieldName.toLowerCase()) || defaultFieldsArray.includes(fieldName.toUpperCase());
  const buttonClass = isDisabled ? "btn-sv1" : "btn-sv2";
  return (
    <div className='template-text-box-container'>
      <div className='template-text-box-container-heading'>
        <span>Edit field</span>
      </div>
      <div className='template-text-box-container-mandatory-check'>
        <input type="checkbox" checked={is_mandatory}
          onChange={handleCheckboxChange} />
        <span>Mandatory Field</span>
      </div>
      <div className='template-text-box-container-texttype'>
        <span>Field Name</span>
        <div className='template-text-box-container-field-input'>
          <input type='text' placeholder='Text field' value={fieldName}
            onChange={handleFieldNameChange} />
        </div>
        {error && <div className='error-message' style={{ color: 'red' }}>{error}</div>}
      </div>
      {/* <div className='template-text-box-container-text-switches'>
        <span>Field Input</span>
        <div className='template-text-box-container-switches'>
          <div className='template-text-box-container-values'>
            <span>Numeric value</span>
            <div className={`switch ${is_numeric ? "on" : "off"}`} onClick={handleSwitch1}>
              <div className="circle"></div>
            </div>
          </div>
          <div className='template-text-box-container-values'>
            <span>Special characters</span>
            <div className={`switch ${is_special_character ? "on" : "off"}`} onClick={handleSwitch2}>
              <div className="circle"></div>
            </div>
          </div>
          <div className='template-text-box-container-values'>
            <span>Character limit</span>
            <div className={`switch ${character_limit ? "on" : "off"}`} onClick={handleSwitch3}>
              <div className="circle"></div>
            </div>
          </div>
        </div>
        {characterLimit && (
          <div className='template-text-box-container-characters'>
            <div className='template-text-box-container-characters-div'>
              <input
                placeholder='Enter character length'
                className='template-text-box-container-number'
                value={characterCount}
                onChange={handleCharacterCountChange}
              />
              <span>Eg: 100</span>
            </div>
          </div>
        )}
      </div> */}
      <div className="collab-members-btns1">
        <div className="collab-btns1">
          <button className={buttonClass} onClick={handleUpdate}>
            Save
          </button>

          <button className="btn-cl1" onClick={handleCancel}>Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default TextTemplate
