

import React, { useState, useEffect } from "react";
import "../DepartmentPages/Department.css";
import "./TicketList.css";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import axios from "axios";
import { BASE_URL, GRNVIEW, POCREATE } from "../../constants/config";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DepartmentTicketPopup from "../DepartmentPages/DepartmentTicketPopup";
import Loading from "../Common/Loading/Loading";
import TicketTitlePopUp from "../Common/CommonJsx/TitlePopUp";
import { useTour } from "@reactour/tour";
import NameProfile from "../Common/CommonJsx/NameProfile";
import Pagination from "../Common/CommonJsx/Pagination";
import { debounce } from "../../constants/helper";
import SearchFilter from "../Common/CommonJsx/SearchFilter";


function GrnParts({ type }) {
    const itemsPerPage = 10;
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [length, setLength] = useState(0);
    const [departmentList, setDepartmentList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [ticketPopUp, setTicketPopUp] = useState(false);
    const [ticketTitle, setTicketTitle] = useState("");
    const [resetButton, setResetButton] = useState(false);
    const [loading, setLoading] = useState(false);


    // Check local storage for tour completion status
    // const isTourCompleted = localStorage.getItem('tourCompleted');
    const { setIsOpen, setSteps, isOpen, setCurrentStep } = useTour();
    useEffect(() => {
        // Reset filters when type changes
        setSelectedStatus(null);
        setSearchTerm("");
    }, [type]);
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleNextClick = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };
    // const handleStatusChange = (selectedOption) => {
    //     setSelectedStatus(selectedOption.value);
    //     setResetButton(true)
    // };
    const handleReset = async () => {

        // setDepartmentId('')

        setSelectedStatus(null)
        setSearchTerm('')
        // setPartAction('')
        setResetButton(false)


        // setReset(false)
    };
    const handlePreviousClick = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleDone = () => {
        setIsOpen(false);
        Cookies.set("ECTourCompleted", true, { expires: 365 });
    };

    const ticketSteps = [
        {
            selector: '[data-tour="step-8"]',
            content: (
                <div>
                    <p>Create your new EC</p>
                    {/* <p>
                Create your new ticket by choosing department and fill the fields
              </p> */}
                    <div className="tour-btns">
                        <button onClick={() => handleNextClick()} className="tour-next">
                            Next
                        </button>
                    </div>
                </div>
            ),
        },
        {
            selector: '[data-tour="step-9"]',
            content: (
                <div>
                    <p>Click to view EC</p>
                    {/* <p>Click to view tickets assigned to you</p> */}
                    <div className="tour-btns">
                        <button onClick={() => handlePreviousClick()} className="tour-back">
                            Back
                        </button>
                        <button onClick={() => handleDone()} className="tour-next">
                            Done
                        </button>
                    </div>
                </div>
            ),
        },
    ];
    const emptyTicketSteps = [
        {
            selector: '[data-tour="step-8"]',
            content: (
                <div>
                    <p>Create your new EC</p>

                    {/* <p>
                Create your new ticket by choosing department and fill the fields
              </p> */}
                    <div className="tour-btns">
                        <button onClick={() => handlePreviousClick()} className="tour-back">
                            Back
                        </button>
                        <button onClick={() => handleDone()} className="tour-next">
                            Done
                        </button>
                    </div>
                </div>
            ),
        },
    ];

    const [hasOpened, setHasOpened] = useState(false);

    let ponumber = useParams();



    const nav = useNavigate();
    const { id } = useParams();


    const handleClosePopup = () => {
        setIsPopupOpen(false);

    };
    const handleTitleClick = (event, department) => {
        event.stopPropagation();
        setTicketPopUp(true);
        setTicketTitle(department.data.default_fields_data.purchase_order_title.value);
    };

    const HandleTitleClose = () => {
        setTicketPopUp(false);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setResetButton(true)
    };

    const allTickets = async () => {

        try {
            setLoading(true);
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            // let response,


            // if (type === "ec-template") {
            //   endPoint = "ec-template";
            // } if (type === "ec-deviation") {
            //   endPoint = "ec-deviations";
            // }
            const response = await axios.get(
                `${BASE_URL}/v1/goods-receipts/get-grn-list`,

                {
                    params: {
                        page: currentPage,
                        limit: itemsPerPage,
                        search: searchTerm,
                        purchase_order_number: type === 'po-num' ? ponumber.ponumber.toString() : '',

                    },
                    headers: headers,
                }
            );

            const details = response.data.data.grn_list;
            console.log(response.data.data.grn_list)
            const paginationDetails = response.data.data.pagination;
            console.log(details);
            setLength(details.length);

            setDepartmentList(details);
            setTotalPages(paginationDetails.total_pages);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleOpenEcForm = () => {
        // setIsPopupFormOpen(!isPopupFormOpen);
        nav(POCREATE)
    };

    useEffect(() => {
        if (searchTerm !== "" || selectedStatus) {
            debounce(allTickets)()
        } else {
            allTickets();
        }
    }, [currentPage, itemsPerPage, type, searchTerm]);



    // const handlePreviousPage = () => {
    //     if (currentPage > 1) {
    //         setCurrentPage(currentPage - 1);
    //     }
    // };

    const handleTicketView = (itemId) => {
        // ec-ticket-view

        //   let navigateTo;
        //   if (type === "ec-template") {
        //     navigateTo = "ec-ticket-view";
        //   } else {
        //     navigateTo = "deviation-ticket-view";
        //   }
        nav(GRNVIEW(itemId));
        // window.location.pathname = `/ec-ticket-view/${itemId}`;
    };

    const handleHoverTour = () => {
        const tourCompleted = localStorage.getItem("ECTourCompleted");
        if (!tourCompleted) {
            setIsOpen(true);
            setSteps(length > 0 ? ticketSteps : emptyTicketSteps);

            setHasOpened(true);
        }
        if (tourCompleted === true) {
            setIsOpen(false);
        }

        // Check if the tour is closing after being opened
        if (!isOpen && hasOpened) {
            setIsOpen(false);

            Cookies.set("ECTourCompleted", true, { expires: 365 });
        }
    };


    return (
        <>
            {/* <TicketTemplateTopNav/> */}

            <>
                <>
                    <div
                        className="depart-main"
                        style={{ marginTop: "80px" }}
                        onMouseEnter={handleHoverTour}
                    >
                        <>
                            {" "}
                            <div className="spn-depart">
                                {id ? (
                                    <>
                                        <div className="no-ticket-view-right">
                                            {/* <ArrowBackIcon onClick={handleBack} style={{ cursor: 'pointer' }} />
                        <span>Programs / </span> */}
                                            <span style={{ color: "#610BEE" }}>EC</span>
                                            <span> Ticket Progress</span>
                                        </div>
                                        {/* <div className='no-ticket-progress'>
                Ticket Progress
              </div> */}
                                    </>
                                ) : (
                                    <>
                                        <span>
                                            GRN &gt;  Parts GRN

                                        </span>
                                    </>
                                )}

                                {/* {length < 1 ? (
                    ""
                  ) : (
                    <> */}

                                {/* <button
                                    className="tkt-btn"
                                    onClick={handleOpenEcForm}
                                    data-tour="step-8"
                                >
                                    <AddOutlinedIcon />


                                    {type === "po" && "New PO"}
                                   
                                </button> */}
                                {/* </>
                  )} */}
                            </div>
                        </>

                        <div className="tck-fltr">

                            <SearchFilter searchTerm={searchTerm} handleSearchChange={handleSearchChange} listType='grn' />
                            {resetButton && <button
                                className="reset-btn"
                                onClick={handleReset}
                                data-tour="step-11"
                            >
                                reset <TuneOutlinedIcon />
                            </button>}

                        </div>
                        
                     
                                    <div className="department-table">
                                        <table className="department-table-data">
                                            <thead>
                                                <tr>

                                                    <th className="tck-header">PO number </th>

                                                    <th className="tck-header">Vendor name</th>
                                                    <th className="tck-header">GRN Id </th>

                                                    <th className="tck-header">Status</th>


                                                </tr>
                                            </thead>
                                            {loading ? <Loading /> : <tbody>
                                                {departmentList.map((department, item) => (
                                                    <tr
                                                        key={item}
                                                        onClick={() =>
                                                            handleTicketView(department._id)
                                                        }
                                                        style={{ cursor: "pointer" }}
                                                        data-tour="step-9"
                                                    >


                                                        <td title={department.purchase_order_number}>{department.purchase_order_number} </td>

                                                        <td>{department.supplier_name}</td>
                                                        <td>{department.grn_sequence_id}</td>

                                                        <td>{department.status}</td>




                                                    </tr>
                                                ))}
                                            </tbody>}

                                        </table>
                                    </div>
                                

                        {(totalPages > 1 && !loading)  && (
                            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
                        )}

                    </div>
                    {isPopupOpen && (
                        <DepartmentTicketPopup onClose={handleClosePopup} type={type} />
                    )}

                    {ticketPopUp && (
                        <TicketTitlePopUp
                            header='GRN title'
                            onClose={HandleTitleClose}
                            ticketTitle={ticketTitle}
                        />
                    )}

                </>
            </>
        </>
    )
}

export default GrnParts