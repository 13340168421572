import React,{useState,useEffect} from 'react'
import { BASE_URL } from "../../constants/config";
import Cookies from "js-cookie";
import axios from "axios";
import { ASSET_PREFIX_URL } from "../../constants/config";
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton';

function PoDepartment({departments,handleClose}) {

    
  return (
    <div className="part-number-popup-page">
    <div className="part-number-popup-div">
      <div className="part-number-popup-text">
        <span>Departments list</span>
        <PopupCloseButton handleClose={handleClose} />
      </div>
      {/* <div className="part-number-popup-desc">
        <span>Your variable is Alphabet with 2 length</span>
        <span>E.g. Variable = EN , ‘meaning’ = Engineering</span>
      </div> */}
      <div className="part-number-popup-hori">{/* HORIZONTAL LINE */}</div>
      <div className="part-number-popup-inputs-container">
        {departments.map((inputSet, index) => (
          <div key={index} className="part-number-popup-inputs">
            <input
              type="text"
              placeholder="Enter Variable"
              value={inputSet.unique_initial}
              disabled
              // maxLength={characterLength}
             
            />
            <input
              type="text"
              placeholder="Enter Meaning"
              value={inputSet.department_name}
              disabled
            />
            {/* <img style={{ cursor: 'pointer' }}
              src={`${ASSET_PREFIX_URL}attri-delete.png`}
              alt='delete'
              onClick={() => handleRemoveInput(index)}
            /> */}
          </div>
        ))}

       
       
      </div>
     
    </div>
  </div>
  )
}

export default PoDepartment