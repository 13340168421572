import React, { useState, useEffect } from 'react';
import { BASE_URL, HEADERS } from '../../constants/config';
import Select from 'react-select';
import axios from 'axios';
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton';
import Cookies from 'js-cookie';
import { previousDay } from 'date-fns';
import zIndex from '@mui/material/styles/zIndex';

function CreateAttributes({ handleClose, attributeOptions, partNumber ,setUpdatedData}) {
    const [selectedValues, setSelectedValues] = useState({});
    const [errors, setErrors] = useState('');
    const [existingAttributes, setExistingAttributes] = useState([]);

    const handleChange = (selected, title) => {
        setSelectedValues(prevState => ({
            ...prevState,
            [title]: selected.value, // Update selected values
        }));
        console.log(selected.value);
    };

    useEffect(() => {
        if (partNumber) {
            getAttributes();
        }
    }, [partNumber]);

    // Fetch existing attributes for the part number
    const getAttributes = async () => {
        try {
            const headers = HEADERS
            const response = await axios.get(
                `${BASE_URL}/v1/part-nomen/get-mbom-attribute`,
                {
                    params: {
                        part_number: partNumber,
                    },
                    headers,
                }
            );
            const fields = response.data?.data?.mbom_attribute.mbom_attribute_data || [];
            setExistingAttributes(fields);
            console.log(response)
            // Pre-fill form with existing attribute values
            const prefilledValues = {};
            fields.forEach(item => {
                prefilledValues[item._id] = item.value;
            });
            console.log(prefilledValues)
            setSelectedValues(prefilledValues);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCreate = async () => {
        // Check if all attributes are filled
        const allAttributesFilled = attributeOptions.every(item => {
            return selectedValues[item._id] !== undefined && selectedValues[item._id] !== null;
        });
    
        if (!allAttributesFilled) {
            setErrors('Please fill all attributes');
            return;
        }
    
        const headers = HEADERS
    
        // Build the final attribute array, updating existing or adding new attributes
        const attributes = attributeOptions.map(item => {
            const existingAttribute = existingAttributes.find(attr => attr.title === item.title);
            return {
                
                _id:item._id,
                value: selectedValues[item._id], 
               
            };
        });
    
        console.log(attributes);
    
        try {
            const response = await axios.post(
                `${BASE_URL}/v1/part-nomen/update-mbom-attribute`,
                {
                    part_number: partNumber,
                    mbom_attribute_data: JSON.stringify(attributes), // Send as stringified JSON
                    type: 'mbom',
                },
                { headers }
            );
    
            if (response.data.meta.success === false) {
                console.error(response.data.meta.message);
                setErrors(response.data.meta.message);
            } else {
                setUpdatedData(attributes);
                handleClose();
                console.log('Attributes updated successfully');
            }
        } catch (error) {
            console.error("An error occurred during the request:", error);
            setErrors("An error occurred during the request. Please try again.");
        }
    };
    

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: '300px',
            backgroundColor: 'transparent',
            border: '1px solid #EDF2F7',
            boxShadow: 'none',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? 'transparent' : 'white',
            color: state.isSelected ? 'black' : 'inherit',
            cursor: 'pointer',
            ':hover': {
                background: 'lightgray',
            },
            zIndex:9999
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999, // Set a high z-index value
        }),
    };
    
    return (
        <div className='create-part-number-popup-page'>
            <div className='create-part-num-div' style={{ width: '50%' }}>
                <div className='create-part-num-div-top'>
                    <span>Create Attributes</span>
                    <PopupCloseButton handleClose={handleClose} />
                </div>

                <div className='create-part-num-div-form' style={{height:'auto'}}>
                    <div className="create-part-num-div-form-dynamic">
                        {attributeOptions ? (
                            attributeOptions.map((item, index) => (
                                <div className="create-part-num-div-form-label-input" key={index}>
                                    <span>{item.title}*</span>
                                    <Select
                                        styles={customStyles}
                                        options={item.options.map(option => ({
                                            value: option,
                                            label: option,
                                        }))}
                                        placeholder={`Select ${item.title}`}
                                        value={
                                            selectedValues[item._id]
                                                ? { value: selectedValues[item._id], label: selectedValues[item._id] }
                                                : null  // If no value is selected, show the placeholder
                                        }
                                        onChange={(selected) => handleChange(selected, item._id)}
                                    />

                                </div>
                            ))
                        ) : (
                            <p>No attributes available to select</p>
                        )}
                    </div>
                </div>
                {errors && <span className='error-message'>{errors}</span>}
                <div className='create-part-num-div-btns'>
                    <button onClick={handleCreate}>Create</button>
                    <button onClick={handleClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default CreateAttributes;
