import React, { useEffect, useState, useRef, useCallback } from "react";
import NameProfile from "../Common/CommonJsx/NameProfile";
import Select from "react-select";
import "./PartNumberView.css";


function PartDetailsTab({memberDetails, customStyles, attributeOptions, shouldEdit, handleUpdate, makeByCodeOptions, partDetails, createdDate}) {
    return (
        <div>
            <div className="part-view-inputs" style={{marginBottom:'4%'}}>
                <span>Created by</span>
                <div className="part-view-owner">


                    <NameProfile userName={memberDetails.fullName} width='28px' memberPhoto={memberDetails.photo} fontweight='500' />

                    {memberDetails.fullName}
                </div>
            </div>
            <div className="part-view-inputs" style={{marginBottom:'4%'}}>
                <span>Make/buy code</span>
                <Select styles={customStyles}
                    isDisabled={!shouldEdit}
                    onChange={(selected) => handleUpdate('make_buy_code', selected.value)}
                    //  onChange={(selected) => handlemeasureChange(selected)}
                    options={makeByCodeOptions}
                    value={{ value: partDetails.make_buy_code, label: partDetails.make_buy_code }}
                />
            </div>
            {attributeOptions.map((item, index) => (
                <div key={index}>
                    <div className="part-view-inputs" style={{marginBottom:'4%'}}>
                        <span>{item.title}</span>
                        <Select styles={customStyles}
                            isDisabled={!shouldEdit}
                            onChange={(selected) => handleUpdate(item._id, selected.value, 'attribute_data')}
                            //  onChange={(selected) => handlemeasureChange(selected)}
                            options={item.options.map((option) => ({
                                value: option,
                                label: option,
                            }))}
                            value={{ value: item.value, label: item.value }}
                        />
                    </div>
                </div>

            ))}

            <div className="part-view-inputs" style={{marginBottom:'4%'}}>
                <span>Created on</span>
                <input type="date" disabled value={createdDate} />
            </div>
        </div> 
    )
}
export default PartDetailsTab