import React from 'react'
import Loading from '../Common/Loading/Loading'
import NameProfile from '../Common/CommonJsx/NameProfile'
import { ASSET_PREFIX_URL } from '../../constants/config';
import SearchFilter from '../Common/CommonJsx/SearchFilter';

function ApproversComponent({editable,searcingApprovals,handleSeachApprovals,approvalLoading,
    approvalListOfMembers,searchCondition,selectedMembers,handleAddToApprovalList,ecApprovalStatus}
) {

  return (
    <>
    {editable && 
   
    <SearchFilter searchTerm={searcingApprovals} handleSearchChange={handleSeachApprovals} listType='approvals to add...'/>
    }
    {approvalLoading ? (
      <div style={{marginTop:'10px'}}>
<Loading smallScreen={true} />
      </div>
      
    ) : (
      approvalListOfMembers.length === 0 ? (
        <div className='data-not-found-img'>
          <span>Approvals not found!</span>
        </div>
      ) : (
        <>
          {searchCondition ? (
            <div className="part-options-child-div-1">
              {approvalListOfMembers.map((member, index) => (
                <div
                  key={member._id}
                  className={`part-options-child ${selectedMembers.some(selectedMember => selectedMember._id === member._id) ? 'selected' : ''}`}
                // onClick={() => handleMemberClick(member)}
                >
                  <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <NameProfile userName={member.entity_id?.fullName} memberPhoto={member.entity_id?.photo} width="36px" fontweight="500" />
                    <span>{member.entity_id?.fullName}</span>
                  </div>
                  <div>
                    <button className="part-view-delete-parts" onClick={() => handleAddToApprovalList(member)}>+ Add</button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="part-options-child-div-1">
              {approvalListOfMembers.map((member, index) => (
                <div
                  key={member._id}
                  className={`part-options-child ${selectedMembers.some(selectedMember => selectedMember._id === member._id) ? 'selected' : ''}`}
                // onClick={() => handleMemberClick(member)}
                >
                  <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <NameProfile userName={member.fullName} memberPhoto={member.photo} width="36px" fontweight="500" />
                    <span>{member.fullName}</span>
                  </div>
                  <div>

                    {ecApprovalStatus !== 'Draft' && <>
                      {(member.status === 'Draft' || member.status === '') && ''}
                      {/* {member.status === 'reject' && <img src={`${ASSET_PREFIX_URL}rejected.png`} />} */}
                      {(member.status === 'Released'||member.status === 'trial_approved'||member.status === 'trial_result_approved') && <img src={`${ASSET_PREFIX_URL}approved.png`} />}
                      {(member.status === 'Pending Approval'||member.status === 'pending_for_trial_approval'||member.status === 'pending_for_trial_result_approval') && <img src={`${ASSET_PREFIX_URL}pending.png`} />}
                    </>}


                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )
    )}

  </>
  )
}

export default ApproversComponent