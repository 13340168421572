import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import PotitleCard from './PotitleCard';
import './PoDownload.css';
import SupplierDetailsPo from './SupplierDetailsPo';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import InnovoiceTable from './InnovoiceTable';
import TermsAndConditions from './TermsAndConditions';

const DownloadPo = forwardRef(({ selectedAddresses, orgDetails, supplierDetails, tableViewData, defaultData, customData, generatePoNumber, sequence_id, editorText }, ref) => {

  const printRef = useRef();
  
  const orgDetailsString = localStorage.getItem('orgDetails');
  const orgDetailslogo = JSON.parse(orgDetailsString); // Convert to an object

  // Access the logo property
  const logo = orgDetailslogo.logo;
  useImperativeHandle(ref, () => ({
    generatePdf: async () => {
      const element = printRef.current;
      const canvas = await html2canvas(element, {
        useCORS: true,
        scale: 1 // Increase the scale to improve quality
      });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4'); // Specify the orientation and unit

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgProps = pdf.getImageProperties(imgData);
      const imageAspectRatio = imgProps.width / imgProps.height;
      let renderedWidth, renderedHeight;

      if (imageAspectRatio > 1) {
        renderedWidth = pdfWidth;
        renderedHeight = pdfWidth / imageAspectRatio;
      } else {
        renderedHeight = pdfHeight;
        renderedWidth = pdfHeight * imageAspectRatio;
      }

      pdf.addImage(imgData, 'PNG', 0, 0, renderedWidth, renderedHeight);
      pdf.save(`${generatePoNumber}.pdf`);
    }
  }));

  return (
    <div ref={printRef} className='download-po-container' style={{ position: 'absolute', top: '-9999px' }}>
      <PotitleCard orgDetails={orgDetails} selectedAddresses={selectedAddresses} logo={logo}/>
      <SupplierDetailsPo supplierDetails={supplierDetails} compType='supply' generatePoNumber={generatePoNumber} sequence_id={sequence_id} />
      <div className='horizontal-line-po'></div>
      <SupplierDetailsPo supplierDetails={defaultData} compType='default' selectedAddresses={selectedAddresses}/>
      <InnovoiceTable tableViewData={tableViewData} />
      <div className='horizontal-line-po'></div>
      <SupplierDetailsPo supplierDetails={customData} compType='custom' />
      <TermsAndConditions editorText={editorText} />
    </div>
  );
});

export default DownloadPo;
