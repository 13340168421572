import React, { useState, useEffect } from "react";
import "../DepartmentPages/Department.css";
import "./TicketList.css";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import axios from "axios";
import { BASE_URL, MBOMVIEW } from "../../constants/config";
import Cookies from "js-cookie";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import TicketTitlePopUp from "../Common/CommonJsx/TitlePopUp";
import NameProfile from "../Common/CommonJsx/NameProfile";
import CreateMbom from "../MbomPages/CreateMbom";
import DateFormateForList from "../Common/CommonJsx/DateFormateForList";
import Pagination from "../Common/CommonJsx/Pagination";
import { debounce } from "../../constants/helper";
import SearchFilter from "../Common/CommonJsx/SearchFilter";

function MBOMlist() {
    const itemsPerPage = 10;
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [length, setLength] = useState(0);
    const [BomtList, setMbomtList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [updatedData,setUpdatedData] = useState(false)
    const [ticketPopUp, setTicketPopUp] = useState(false);
    const [ticketTitle, setTicketTitle] = useState("");
    const [resetButton, setResetButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openAddressPopUp, setOpenAddressPopUp] = useState(false)



    useEffect(() => {
        // Reset filters when type changes
        setSelectedStatus(null);
        setSearchTerm("");
    }, []);



    const handleReset = async () => {
        setSelectedStatus(null)
        setSearchTerm('')
        setResetButton(false)
    };








    const nav = useNavigate();
    const { id } = useParams();


    const handleClosePopup = () => {
        setIsPopupOpen(!isPopupOpen);

    };
    const handleTitleClick = (event, department) => {
        event.stopPropagation();
        setTicketPopUp(true);
        setTicketTitle(department.data.default_fields_data.purchase_order_title.value);
    };

    const HandleTitleClose = () => {
        setTicketPopUp(false);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setResetButton(true)
    };

    const allMboms = async () => {

        try {
            setLoading(true);
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };

            const response = await axios.get(
                `${BASE_URL}/v1/manufac-bom/get-product-list`,
                {
                    params: {
                        page: currentPage,
                        limit: itemsPerPage,
                        search: searchTerm,

                    },
                    headers: headers,
                }
            );
            const details = response.data.data.mbom_list;
            const paginationDetails = response.data.data.pagination;
            setLength(details.length);
            setMbomtList(details);
            setTotalPages(paginationDetails.total_pages);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleOpenPoForm = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    useEffect(() => {
        if (searchTerm !== "" || selectedStatus) {
            debounce(allMboms)()
        } else {
            allMboms();
        }
    }, [currentPage, itemsPerPage, searchTerm,updatedData]);





    const handleTicketView = (itemId) => {

        nav(MBOMVIEW(itemId));
        console.log(itemId)

    };




    return (
        <>
            {/* <TicketTemplateTopNav/> */}

            <>
                <>
                    <div
                        className="depart-main"
                        style={{ marginTop: "80px" }}

                    >
                        <>
                            {" "}
                            <div className="spn-depart">
                                {id ? (
                                    <>
                                        <div className="no-ticket-view-right">

                                            <span style={{ color: "#610BEE" }}>EC</span>
                                            <span> Ticket Progress</span>
                                        </div>

                                    </>
                                ) : (
                                    <>
                                        <span>
                                        Products

                                        </span>
                                    </>
                                )}

                                {/* {length < 1 ? (
                    ""
                  ) : (
                    <> */}

                                <button
                                    className="tkt-btn"
                                    onClick={handleOpenPoForm}
                                    data-tour="step-8"
                                >
                                    <AddOutlinedIcon />


                                    New  product
                                </button>
                                {/* </>
                  )} */}
                            </div>
                        </>

                        <div className="tck-fltr">
                            {/* {length < 1 ? (
              ""
            ) : (
              <> */}

                            <SearchFilter searchTerm={searchTerm} handleSearchChange={handleSearchChange} listType='Products...' />

                            {resetButton && <button
                                className="reset-btn"
                                onClick={handleReset}
                                data-tour="step-11"
                            >
                                reset <TuneOutlinedIcon />
                            </button>}
                            {/* </>
            )} */}

                            {/* {id ? '' : <> */}
                        </div>
                       
                                    <div className="department-table">
                                        <table className="department-table-data">
                                            <thead>
                                                <tr>
                                                    {/* <th className="id-header">ID</th> */}
                                                    <th className="depart-header">Product name </th>
                                                    <th className="depart-header">Part number </th>

                                                    {/* <th className="tck-header">PO status</th> */}
                                                    <th className="tck-header">Created by</th>
                                                    <th className="tck-header">Updated at</th>
                                                    <th className="tck-header">Action</th>
                                                </tr>
                                            </thead>
                                            {loading ? <Loading/> :  <tbody>
                                                {BomtList.map((item, index) => (
                                                    <tr
                                                        key={index}

                                                        style={{ cursor: "pointer" }}
                                                        data-tour="step-9"
                                                    >
                                                        {/* <td>{item.sequence_id}</td> */}


                                                        <td title={item.product_name}>
                                                            {item.product_name.length > 30 ? (
                                                                <>
                                                                    {item.product_name.slice(0, 30)}
                                                                    ...
                                                                    <button className="view-more-text" onClick={(event) => handleTitleClick(event, item)}>
                                                                        view
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                item.product_name
                                                            )}
                                                        </td>
                                                        <td>{item.part_number}</td>


                                                        <td>
                                                            <div
                                                                className="member-option"
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: "10px",
                                                                }}
                                                            >
                                                                <NameProfile
                                                                    userName={item?.memberDetails?.fullName ?? ''}
                                                                    memberPhoto={item?.memberDetails?.photo ?? ''}
                                                                    width="26px"
                                                                    fontweight="500"
                                                                />

                                                                <span className="member-fullname">
                                                                    {item?.memberDetails?.fullName ?? ''}
                                                                </span>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <DateFormateForList formateTime={item.updatedAt} />
                                                        </td>
                                                        <td>
                                                            {item.productExistsInBOM && <button className='production-edit'

                                                                onClick={() =>
                                                                    handleTicketView(item._id)
                                                                }
                                                            >
                                                                View BOM
                                                            </button>}

                                                        </td>



                                                       

                                                    </tr>
                                                ))}
                                            </tbody>}
                                           
                                        </table>
                                    </div>
                               

                       
                        {(totalPages > 1 && !loading)  && (
                            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
                        )}

                    </div>


                    {ticketPopUp && (
                        <TicketTitlePopUp
                        header='Product name'
                            onClose={HandleTitleClose}
                            ticketTitle={ticketTitle}
                        />
                    )}
                    {isPopupOpen && (
                        <CreateMbom handleClose={handleClosePopup} allMboms={allMboms} setUpdatedData={setUpdatedData}/>
                    )}

                </>
            </>
        </>
    )
}

export default MBOMlist