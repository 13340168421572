import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { DateRangePicker } from 'react-date-range';
import { addDays, format } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import Select from 'react-select';
import './PoCreate.css';
import { ASSET_PREFIX_URL, PHOTO_LINK,BASE_URL } from '../../constants/config';
import NameProfile from '../Common/CommonJsx/NameProfile';

function PoTemplateFieldsView({ data, fieldsType, setData, ecApprovalStatus, poType, setDisableUpdate, isDropdownOpen, setIsDropdownOpen,
    setSelectedWarehouse,
    setSelectedAddress,
    selectedAddresses, editable,
    setSelectedDepartment,
    selectedDepartment
}) {
console.log(data)
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);
    const [open, setOpen] = useState(false);
    const ref = useRef(null);
    const [formData, setFormData] = useState({});
    const [selectMember, setSelectMember] = useState([]);
    const [warehouseAddress, setWarehouseAddress] = useState({});
    const [departmentList, setDepartmentList] = useState([]);
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        // Initialize formData with current data values
        if (!data) return; // Added check to return early if data is undefined

        const initialFormData = {};
        Object.keys(data).forEach(fieldName => {
            const fieldKey = fieldName.replace(/\s+/g, '_').toLowerCase();
            initialFormData[fieldKey] = data[fieldName].value || '';
        });
        setFormData(initialFormData);
    }, [data]);

    // Update data state whenever formData changes
    useEffect(() => {
        if (!data) return; // Added check to return early if data is undefined

        const updatedData = { ...data };
        Object.keys(formData).forEach(fieldKey => {
            const fieldName = Object.keys(data).find(key => key.replace(/\s+/g, '_').toLowerCase() === fieldKey);
            if (fieldName) {
                updatedData[fieldName] = {
                    ...updatedData[fieldName],
                    value: formData[fieldKey]
                };
            }
        });
        setData(prevData => {
            // Merge the updatedData into the previous state
            const updatedState = { ...prevData };
            Object.keys(updatedData).forEach(fieldName => {
                updatedState[fieldName] = {
                    // ...updatedState[fieldName],
                    value: updatedData[fieldName].value, // Extract the updated value
                    form_field_id: updatedData[fieldName].form_field_id // Preserve the _id
                };
            });
            return updatedState;
        });
    }, [formData, data, setData]);

    // Fetch members on component mount


    const customStyles = {
        // Your custom styles here
    };



    const handleChange = (fieldName, selectedOption) => {

        setDisableUpdate(true);

        const transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();
        const updatedFormData = {
            ...formData,
            [transformedFieldName]: selectedOption
        };
        setFormData(updatedFormData);
    };
    const handleDtaeRangeChange = (fieldName, start, end) => {

        setDisableUpdate(true);

        const transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();
        const updatedFormData = {
            ...formData,
            [transformedFieldName]: [format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd')]
        };
        setFormData(updatedFormData);
    };

    const handlePersonSelectChange = (fieldName, selectedOption) => {
        setDisableUpdate(true);

        const fieldValue = [
            selectedOption.label.props.children[0].props.memberPhoto || '',
            selectedOption.value,
            selectedOption.label.props.children[1],
        ];

        const transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();
        const updatedFormData = {
            ...formData,
            [transformedFieldName]: fieldValue,
        };
        setFormData(updatedFormData);
    };

    const handleMultiSelectChange = (fieldName, value) => {
        let fieldValue = value.map(item => item.label);
        const transformedFieldName = fieldName.replace(/\s+/g, '_').toLowerCase();
        const updatedFormData = {
            ...formData,
            [transformedFieldName]: fieldValue,
        };
        setFormData(updatedFormData);
    };

    const toggleDropdown = (e) => {
        setIsDropdownOpen(!isDropdownOpen);
        e.stopPropagation();
    };

    // Render nothing if data is undefined
    if (!data) {
        console.log('yes')
        return null;
    }
    const handleAddressChange = async (selectedOption, action) => {
        if (action === 'address') {
            setSelectedAddress(selectedOption.label);
            setSelectedWarehouse(selectedOption.value)
        } else {
            console.log(selectedOption)
            setSelectedDepartment(selectedOption)
        }
        setDisableUpdate(true);
    }





    const toggleOpen = () => {
        setOpen(!open);
    };

    const formatDateRange = (start, end) => {
        const startDate = format(start, 'yyyy-MM-dd');
        const endDate = format(end, 'yyyy-MM-dd');
        return `${startDate} to ${endDate}`;
    };

    const fetchWarehouseDetails = async () => {
        try {
            // setLoading(true);
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            const response = await axios.get(BASE_URL + "/v1/warehouse/get-warehouse-list", { headers });

            setWarehouseAddress(response.data.data.warehouse_list)


        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };

    const fetchDepartments = async () => {
        try {
            const headers = {
                "x-auth-token": localStorage.getItem("token"),
            };
            const response = await axios.get(BASE_URL + "/v1/depart/get-departments", { headers });
            const departments = response.data.data.departments;

            setDepartmentList(departments);
            //   fillInputSetsWithDepartments(departments);
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };

    return (
        <>
            {data.length !== 0 && <div className='horizontal-line-po'></div>}

            {(data.length !== 0 && fieldsType === 'custom') && <span>Custom fields</span>}


            <div className='po-template-fields' onClick={() => setIsDropdownOpen(false)}>
                {Object.keys(data).map(fieldName => {
                    const field = data[fieldName];
                    const fieldKey = fieldName.replace(/\s+/g, '_').toLowerCase();
                    console.log(fieldName)
                    return (
                        <div key={fieldName} className='po-mandatory-field-container'>
                            <span>{field.fieldName}</span>
                            {(field.inputType === 'text') && (
                                <input
                                    type='text'
                                    disabled={!editable}
                                    placeholder={field.placeholder || ''}
                                    value={formData[fieldKey] || field.value || ''}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                />
                            )}
                            {(field.inputType === 'paragraph') && (
                                <textarea
                                    type='text'
                                    disabled={!editable}
                                    placeholder={field.placeholder || ''}
                                    value={formData[fieldKey] || field.value || ''}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                />
                            )}
                            {(field.inputType === 'number') && (
                                <input
                                    type='number'
                                    disabled={!editable}
                                    placeholder={field.placeholder || ''}
                                    value={formData[fieldKey] || field.value || ''}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                />
                            )}
                            {(field.inputType === 'single_select') && (
                                <Select
                                    styles={customStyles}
                                    isDisabled={!editable}
                                    options={field.option.map(option => ({ value: option, label: option }))}
                                    value={{ value: formData[fieldKey] || field.value, label: formData[fieldKey] || field.value }}
                                    onChange={(selectedOption) => handleChange(fieldName, selectedOption.value)}
                                />
                            )}
                            {(field.inputType === 'date') && (
                                <input
                                    type='date'
                                    disabled={!editable}
                                    min={field.fieldName !== 'PO Date' ? new Date().toISOString().split("T")[0] : ''}
                                    placeholder={field.placeholder || ''}
                                    value={formData[fieldKey] || field.value || ''}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                />
                            )}
                            {field.inputType === 'date_range' && (
                                <div className='date-range-input-relative'>
                                    <input
                                        value={formatDateRange(field.value[0], field.value[1])}
                                        onClick={toggleOpen}
                                        readOnly
                                        style={{ cursor: 'pointer' }}

                                    />
                                    {open && (
                                        <div ref={ref} style={{ position: 'absolute', zIndex: 1, background: 'white' }}>
                                            <DateRangePicker
                                                onChange={(item) => {
                                                    setState([item.selection]);
                                                    handleDtaeRangeChange(field.fieldName, item.selection.startDate, item.selection.endDate);
                                                }}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                ranges={state}
                                                direction="horizontal"
                                            // onChange={(item) => setState([item.selection])}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            {(field.inputType === 'link') && (
                                <input
                                    type='link'
                                    disabled={!editable}
                                    placeholder={field.placeholder || ''}
                                    value={formData[fieldKey] || field.value || ''}
                                    onChange={(e) => handleChange(fieldName, e.target.value)}
                                />
                            )}
                            {field.inputType === 'multi_select' && (
                                <Select
                                    styles={customStyles}
                                    isMulti
                                    isDisabled={!editable}
                                    options={field.option.map(option => ({ value: option, label: option }))}
                                    value={(formData[fieldKey] || field.value || []).map(val => ({ value: val, label: val }))}
                                    onChange={(selectedOptions) => handleMultiSelectChange(fieldName, selectedOptions)}
                                />
                            )}
                            {field.inputType === 'person' && (
                                <Select
                                    placeholder={field.placeholder}
                                    styles={customStyles}
                                    isDisabled={!editable}
                                    options={selectMember.map(member => ({
                                        value: member.entity_id,
                                        label: (
                                            <div className="member-option" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <NameProfile userName={member.fullName} width='26px' memberPhoto={member.photo} fontweight='500' />
                                                {member.fullName}
                                            </div>
                                        ),
                                    }))}
                                    value={{
                                        value: formData[fieldKey] ? formData[fieldKey][1] : '',
                                        label: (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                }}
                                            >
                                                {formData[fieldKey] && formData[fieldKey][0] !== '' ? (
                                                    <img
                                                        src={PHOTO_LINK + formData[fieldKey][0]}
                                                        alt="options"
                                                        className="owner-image"
                                                    />
                                                ) : (
                                                    <div className="owner-image-span">
                                                        <NameProfile userName={formData[fieldKey] && formData[fieldKey][2]} />
                                                    </div>
                                                )}
                                                {formData[fieldKey] && (formData[fieldKey][2].length < 15 ? formData[fieldKey][2] : formData[fieldKey][2].slice(0, 15) + '...')}
                                            </div>
                                        ),
                                    }}
                                    onChange={(selectedOption) => handlePersonSelectChange(fieldName, selectedOption)}
                                />
                            )}
                            {field.inputType === 'select-person' && (
                                <>
                                    <div className="create-part-num-div-form-input-options-po">
                                        <div className='create-part-num-div-form-input-options-drop' onClick={(e) => toggleDropdown(e)}>
                                            <input
                                                style={{ border: 'none' }}
                                                type="text"
                                                placeholder="Select Approver(s)"
                                            />
                                            <div className='droping-search-div'>
                                                <img className='droping-search' src={`${ASSET_PREFIX_URL}options-drop.png`} alt="" />
                                            </div>
                                        </div>
                                        {isDropdownOpen && (
                                            <div className='part-options-child-div' style={{ width: '292px' }}>
                                                {field.value.map((member) => {
                                                    const isSelected = field.value.some(v => v._id === member.entity_id._id);

                                                    return (
                                                        <div
                                                            key={member._id}
                                                            className={`part-options-child ${isSelected ? 'selected' : ''}`}
                                                        >
                                                            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                                                {member.entity_id?.photo ? (
                                                                    <img
                                                                        src={member.photo ? PHOTO_LINK + member.entity_id?.photo : `${ASSET_PREFIX_URL}default-photo.png`}
                                                                        alt=""
                                                                        style={{
                                                                            borderRadius: "50%",
                                                                            width: "26px",
                                                                            height: "26px",
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div className="owner-image-span">
                                                                        <NameProfile userName={member.entity_id?.fullName} />
                                                                    </div>
                                                                )}
                                                                <span>{member.entity_id?.fullName}</span>
                                                            </div>
                                                            {ecApprovalStatus !== 'Create' && (
                                                                <>
                                                                    {(member.status === 'Create' || member.status === '') && ''}
                                                                    {member.status === 'Approved' && <img src={`${ASSET_PREFIX_URL}approved.png`} alt="approved" />}
                                                                    {member.status === 'Pending for approval' && <img src={`${ASSET_PREFIX_URL}pending.png`} alt="pending" />}
                                                                </>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    );
                })}
                {(fieldsType === 'mandate' && poType !== 'parts-grn-view' && warehouseAddress) &&
                    <div className='po-mandatory-field-container'>
                        <span>Warehouse address</span>
                        <Select
                            isDisabled={!editable}
                            value={{
                                value: selectedAddresses,
                                label: selectedAddresses
                            }}
                            styles={customStyles}
                            placeholder="Choose address"

                            options={warehouseAddress.length&&warehouseAddress.map((item) => ({
                                value: item.warehouse_sequence_id,
                                label: item.warehouse_default_data.warehouse_name.value,
                            }))}
                            onFocus={fetchWarehouseDetails}
                            onChange={(selectedOption) => handleAddressChange(selectedOption, 'address')}
                        />
                    </div>
                }
                {(fieldsType === 'mandate' && poType !== 'parts-grn-view') &&
                    <div className='po-mandatory-field-container'>
                        <span>Select department</span>
                        <Select
                            isDisabled={!editable}
                            value={{ value: selectedDepartment.entity_id, label: selectedDepartment.department_name }}
                            styles={customStyles}
                            placeholder="Choose department"
                            options={departmentList.map((item) => ({
                                value: item.entity_id,
                                label: item.department_name,
                                entity_id:item.entity_id,
                                department_name:item.department_name
                            }))}
                            onFocus={fetchDepartments}
                            onChange={(selectedOption) => handleAddressChange(selectedOption, 'dept')}
                        />
                    </div>
                }
            </div>
        </>
    );
}

export default PoTemplateFieldsView;
