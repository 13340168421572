import React, { useState } from 'react'
import {  ASSET_PREFIX_URL } from "../../constants/config";
import Dots from '../Common/DotsDiv/Dots';


const TemplateItem = ({
  index,
  activeIndex,
  handleItemClick,
  handleItemDelete,
  customActiveIndex,
  item,
  setDroppedElements,
  customDetails,
  droppedElements,
  itemSet,
  handleIndex,
  isAdmin,
  isDroppedActive, setSaveButton
}) => {
  const [isDraggingOver, setIsDraggingOver] = useState(null);
  item.id = index;
  
  const getClassName = (elementIndex, activeIndex, customActiveIndex, isDroppedActive) => {
    if (activeIndex === elementIndex) {
      return "active";
    } else if (customActiveIndex === elementIndex) {
      return "custom-active";
    } else {
      return "";
    }
  };
  const getShowHide = (elementIndex, activeIndex, customActiveIndex, isDroppedActive) => {
    if (activeIndex === elementIndex) {
      return "show";
    } else if (customActiveIndex === elementIndex) {
      return "custom-show"; // You can use a different class for customActiveIndex if needed
    } else {
      return "hide";
    }
  };

  const handleDragStart = (event, item) => {
    console.log(item)
    event.dataTransfer.setData('text/plain', JSON.stringify(item));
    event.dataTransfer.effectAllowed = 'move';

  };

  const handleDragOver = (event,index) => {
    if(isAdmin === true&& event.dataTransfer.types.includes('text/plain')){
    event.preventDefault();
    const rect = event.currentTarget.getBoundingClientRect();
    const offset = event.clientY - rect.top;
    // setHoverPosition(offset);
    setIsDraggingOver(index);
    }
  };
  const handleDragLeave = () => {
    if(isAdmin === true){
    setIsDraggingOver(null);
    }
  };

  const handleDrop = (event, index) => {
    if (isAdmin === true) {
      setIsDraggingOver(null);
      setSaveButton(false);
      event.preventDefault();
  
      try {
        const droppedItem = JSON.parse(event.dataTransfer.getData('text/plain'));
        console.log(droppedItem);
        
        // Reorder the elements when dropped
        const newElements = [...droppedElements];
        const fromIndex = newElements.findIndex((element) => element.id === droppedItem.id);
        let toIndex = newElements.findIndex((element) => element.id === item.id);
        if(toIndex<=droppedItem.id){
          toIndex=toIndex===0 ? 0 : toIndex
          console.log(toIndex)
        }else{
          toIndex=(toIndex-1)<=0?0:(toIndex-1)
        }
        
        console.log(fromIndex);
        console.log(toIndex);
        newElements.splice(fromIndex, 1);
        newElements.splice(toIndex, 0, droppedItem);
        console.log(newElements)
        setDroppedElements(newElements);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        // Handle the error, e.g., by providing a default value or displaying an error message
      }
    }
  };
  


  return (
    <>
    {isAdmin === true ?  <div
          className={`horizontal-line ${(isDraggingOver===index) ? 'dragging-over-class' : 'not-dragging-over-class'}`}
        />:''}
    
      <div
        // ref={(node) => drag(node)}
        // ref={dragRef}
        onDragStart={(e) => handleDragStart(e, item)}
        onDragOver={(e) => handleDragOver(e,index)}
        onDragLeave={() => handleDragLeave()}
        onDrop={(e) => handleDrop(e, index)}
        draggable="true"
        key={index}
        className={`template-draged-items ${getClassName(
          index,
          activeIndex,
          customActiveIndex,
          isDroppedActive
        )} `}
        // style={{
        //   border: !isDraggingOver ? '2px dashed #610bee' : '',
        // }}
        onClick={() => handleItemClick(item, index)}
      >
        {/* Common elements for both custom and dropped items */}
        <div className={`template-edit-fields ${getShowHide(index, activeIndex, customActiveIndex, isDroppedActive)}`}>
          {/* <img src={`${ASSET_PREFIX_URL}template-item-edit.svg`} alt='' width='16px' height='16px' /> */}
          <img src={`${ASSET_PREFIX_URL}delete-icon.png`} alt='' width='16px' height='16px' onClick={() => handleItemDelete(item, index)} />
        </div>
        <div
          className={`template-drag-box ${activeIndex === index ? "active" : ""
            }`}
        // ref={dragRef}
        >
         <Dots/>
        </div>

        {/* Render custom details */}
        {customDetails && (
          <div className="template-item-details">
            <span>{item.fieldName}</span>
            <div className="template-item-details-input">
              <input
                placeholder={`Enter ${item.inputType}`}
                disabled={true}
                style={{ background: "white" }}
              />
            </div>
          </div>
        )}
       
        {/* Render dropped elements */}
        {droppedElements && (
          <div className="template-item-details">
            <span>{item.displayType}</span>
            <div className="template-item-details-input">
              <input
                placeholder={item.placeholder}
                disabled={true}
                style={{ background: "white" }}
              />
            </div>
          </div>
        )}

      </div>


    </>
  );
};

export default TemplateItem