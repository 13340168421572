import React, { useState, useEffect } from "react";
import "./PartNumber.css";
import axios from "axios";
import Select from "react-select";
import Cookies from "js-cookie";
import PartNumberPopUp from "./PartNumberPopUp";
import { ASSET_PREFIX_URL, BASE_URL, HEADERS } from "../../constants/config";
import PartAttributes from "./PartAttributes";
import { useRef } from 'react';
import Loading from "../Common/Loading/Loading";
import Dots from "../Common/DotsDiv/Dots"; 
 


function PartNumber({ type }) {
  const [draggedItem, setDraggedItem] = useState(null);
  const [indexedField, setIndexedField] = useState("");
  const [rightSectionItems, setRightSectionItems] = useState([]);
  const [dragableItemsArrays, setDragableItemsArrays] = useState([])
  const [openAttribute, setOpenAttribute] = useState(false);
  const [intitialValues, setInitialValues] = useState([]);
  const [dropElements, setDropElements] = useState(false);
  const [openPopUp, setopenPopUp] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(null);
  const [partNumbers, setPartNumbers] = useState([]);
  const visibleItems = 3

  const [errors, setErrors] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [showUnique, setShowUnique] = useState(false);
  const [uniqueErrors, setUniqueErrors] = useState('');
  const [savedData, setSavedData] = useState(false);
  
  const [loading, setLoading] = useState(false);

  const handleOpenAttribute = () => {
    setOpenAttribute(!openAttribute);
  }


  const variablesArray = ["Fixed", "Counter","Static"];

  const formattedVersions = Array.from(variablesArray).map((version) => ({
    value: version,
    label: `${version}`,
  }));

  const handleDragStart = (item) => {
    if (isAdmin) {
      setDraggedItem(item);
    }

  };

  const handleDragOver = (e) => {
    if (isAdmin) {

      e.preventDefault();
    }

  };
  const containerRef = useRef(null);
  function scrollToBottom() {
    if (containerRef.current) {
      setTimeout(() => {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }, 100);
    }
  }


  const handleDrop = () => {
    try {
      setSavedData(false);
      // Only add the draggedItem if it's not null
      if (isAdmin) {
        if (draggedItem !== null) {
          setRightSectionItems((prevElements) => {
            const newItem = { ...draggedItem, position: prevElements.length + 1 };
            console.log(newItem)
            return [...prevElements, newItem];
          });
        }
        console.log(rightSectionItems);
        setDraggedItem(null);
        setDropElements(true);

        scrollToBottom();
      }
    } catch (error) {
      console.error("Error during drop operation:", error);
    }
  };

  const handleItemDelete = (item, index) => {
    setRightSectionItems(
      rightSectionItems.filter((elem, i) => i !== index)
    );
    // setSaveButton(false)
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "110px",
      backgroundColor: "transparent",
      border: "none", // Remove the border
      boxShadow: "none", // Remove any box shadow
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hide the line (border)
    }),

    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "transparent" : "white", // Set the background color
      color: state.isSelected ? "black" : "inherit", // Set the text color
      cursor: "pointer", // Add cursor pointer on hover
      ":hover": {
        background: "lightgray", // Change the background color on hover
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px", // Change the font size of the value label
      // Add any other styles you want for the value label
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px", // Change the color of the placeholder text
      // Add any other styles you want for the placeholder text
    }),
    // You can define styles for other parts of the Select component here
  };
  const customDateStyles = {
    control: (provided) => ({
      ...provided,
      width: "210px",
      backgroundColor: "transparent",
      border: "1px solid #edf2f7", // Remove the border
      boxShadow: "none", // Remove any box shadow
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hide the line (border)
    }),

    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "transparent" : "white", // Set the background color
      color: state.isSelected ? "black" : "inherit", // Set the text color
      cursor: "pointer", // Add cursor pointer on hover
      ":hover": {
        background: "lightgray", // Change the background color on hover
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px", // Change the font size of the value label
      // Add any other styles you want for the value label
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px", // Change the color of the placeholder text
      // Add any other styles you want for the placeholder text
    }),
    // You can define styles for other parts of the Select component here
  };

  const handleopenPopUp = (index, variables) => {
    setInitialValues(variables);

    setopenPopUp(!openPopUp);
  };

  const handlegetId = (index) => {
    setIndexedField(index);
    // setInitialValues()
  };
  const handleSelectChange = (index, selected, ele_type) => {
    setSavedData(false)
    if (selected.value === 'Counter') {
      setShowUnique(true)
    }
    let updatedRightSectionItems = [...rightSectionItems];

    // Update the length value for the specific item
    if (ele_type === 'fixed') {
      updatedRightSectionItems[index].variable_selected_type = selected.value;
      updatedRightSectionItems[index].is_unique = false
    } else {
      updatedRightSectionItems[index].default_value = selected.value;
    }

    // setSelectedOptions(updatedRightSectionItems)

    setRightSectionItems(updatedRightSectionItems);
  };

  const handleInputChange = (index, value, character_type) => {
    setSavedData(false)
    const updatedRightSectionItems = [...rightSectionItems];

    // Update the length value for the specific item
    updatedRightSectionItems[index].character_length = value;

    setRightSectionItems(updatedRightSectionItems);

    const updatedPartNumbers = [...partNumbers];

    // if (character_type === "Alphabet") {

    //   const alphaPart = "A".repeat(parseInt(value) || 0);
    //   updatedPartNumbers[index] = alphaPart;
    // } else if (character_type === "Numeric") {

    //   const numeric = "1".repeat(parseInt(value) || 0);
    //   updatedPartNumbers[index] = numeric;
    // } else if (character_type === "Special Character") {
    //   const char = value;
    //   updatedPartNumbers[index] = char;
    // }

    setPartNumbers(updatedPartNumbers);
  };
  const handleDefaultValueChange = (index, value) => {
    setSavedData(false)
    const updatedRightSectionItems = [...rightSectionItems];


    updatedRightSectionItems[index].default_value = value;

    setRightSectionItems(updatedRightSectionItems);

  };

  const handlemeaningChange = (index, value, type) => {
    setSavedData(false)
    const updatedRightSectionItems = [...rightSectionItems];
    updatedRightSectionItems[index].meaning = value;
    setRightSectionItems(updatedRightSectionItems);
  };
  const handleFixedVariables = (value) => {

    const parsedValue = JSON.parse(value);
    // console.log()
    // setErrors('')
    validateFields()
    const updatedRightSectionItems = [...rightSectionItems];

    updatedRightSectionItems[indexedField].fixed_variable_options = parsedValue;

    setRightSectionItems(updatedRightSectionItems);
  };
  const handleRadioButtonChange = (index, value) => {
    setSavedData(false)
    validateFields();
    const updatedRightSectionItems = rightSectionItems.map((item, i) => ({
      ...item,
      is_unique: i === index ? value : false,
    }));
    setUniqueErrors('')
    setRightSectionItems(updatedRightSectionItems);
  };



  const generateDynamicPartName = () => {
    return rightSectionItems.map((item)=>item.default_value).join("").toUpperCase();
  };
  const generateDatePartName =  () => {
   

    const currentDate = new Date();

    const dynamicPart = rightSectionItems.map((item) => {
      
      let startYear = currentDate.getFullYear();
      let endYear = startYear + 1;
  
     
      if (item.variable_selected_type === "Fixed"||item.variable_selected_type === "Static") {
        item.is_unique = false;
        return item.default_value;
      } else if (item.character_type === 'special_character') {
        return item.default_value;
      } else if (item.variable_selected_type === "Counter" && item.is_unique === false) {
        return item.default_value;
      } else if (item.variable_selected_type === "Counter" && item.is_unique === true) {
        return item.default_value;
        // const newCounterValue = item.default_value 
        // const paddedCounterValue = newCounterValue.toString().padStart(item.default_value.length, '0');
        // console.log(paddedCounterValue, 'return count');
        // return paddedCounterValue;
      } else if (item.character_type === "input_variable") {
        return item.default_value;
      } else if (item.character_type === 'date') {

        switch (item.default_value) {
          case 'DD-MM-YYYY':
            return `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
          case 'MM-DD-YYYY':
            return `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`;
          case 'YYYY-MM-DD':
            return `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
          case 'DD-MMM-YYYY':
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return `${currentDate.getDate()}-${months[currentDate.getMonth()]}-${currentDate.getFullYear()}`;
          case 'MM-DD-YY':
            return `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear().toString().substr(-2)}`;
          case 'YYYY-MM-DD-HH-MM':
            const hours = currentDate.getHours().toString().padStart(2, '0');
            const minutes = currentDate.getMinutes().toString().padStart(2, '0');
            return `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}-${hours}-${minutes}`;
          case 'D':
            return currentDate.getDate().toString();
          case 'MM':
            return (currentDate.getMonth() + 1).toString();
          case 'D-MM':
            return `${currentDate.getDate()}-${currentDate.getMonth() + 1}`;
          case 'YYYY':
            return currentDate.getFullYear().toString();;
          case 'YY':
            return currentDate.getFullYear().toString().substr(-2);
          default:
            return "";
        }
      } else if (item.character_type === 'Calendar year') {
        if (item.default_value === 'YYYY') {
          return currentDate.getFullYear().toString();
        } else if (item.default_value === 'YY') {
          return currentDate.getFullYear().toString().substr(-2);
        }
      } else if (item.character_type === 'financial_year') {
        if (currentDate.getMonth() < 3) {
          startYear -= 1;
          endYear -= 1;
      }
      if (item.default_value === 'FY-YYYY') {
        return `FY-${startYear}`;
    } else if (item.default_value === 'YYYY-YYYY') {
        return `${startYear}-${endYear}`;
    } else if (item.default_value === 'FY-YYYY-FY-YYYY') {
        return `FY-${startYear}-FY-${endYear}`;
    } else if (item.default_value === 'YYYY-YY') {
        return `${startYear}-${endYear.toString().substr(-2)}`;
    } else if (item.default_value === 'YYYY-YY') {
        return `${startYear}-${endYear.toString().substr(-2)}`;
    } 
      }
      return "";
    });


    const generatedName = dynamicPart.join("").toUpperCase();

    return generatedName;
  };
 

  const handleDragingStart = (event, item, index) => {
    if (isAdmin) {
      event.dataTransfer.effectAllowed = "move";
      const draggedItem = { ...item, position: index };
      event.dataTransfer.setData("text/plain", JSON.stringify(draggedItem));

    }

  };

  const handleDragingOver = (event, index) => {
    if (isAdmin) {
      if (event.dataTransfer.types.includes('text/plain')) {
        event.preventDefault();

        // setHoverPosition(offset);
        setIsDraggingOver(index);
      }
    }


  };
  const handleDragLeave = () => {
    setIsDraggingOver(null);
  };


  const handleingDrop = (event, index) => {
    setIsDraggingOver(null);
    event.preventDefault();

    try {
      setSavedData(false);
      if (isAdmin) {
        const droppedItem = JSON.parse(event.dataTransfer.getData("text/plain"));
        console.log(droppedItem)
        const newElements = [...rightSectionItems];
        const fromIndex = newElements.findIndex((element, index) => index === droppedItem.position);
        let toIndex = newElements.findIndex((element, id) => id === index);


        // If the dropped item is placed back onto its original index

        // Remove the dragged item from its original position
        const [draggedItem] = newElements.splice(fromIndex, 1);

        // Adjust toIndex if dragging upwards
        if (toIndex <= droppedItem.position) {

          toIndex = toIndex === 0 ? 0 : toIndex
          // toIndex = index+1;
        } else {

          toIndex = (toIndex - 1) <= 0 ? 0 : (toIndex - 1)
          // toIndex = index;
        }

        // Insert the dragged item into the new position
        newElements.splice(toIndex, 0, draggedItem);


        const filteredElements = newElements.filter((element) => element !== null);

        setRightSectionItems(filteredElements);


        // const updatedPartNumbers = filteredElements.map((element) => {
        //   if (element.type === "Alphabet") {
        //     return "A".repeat(parseInt(element.values) || 0);
        //   } else if (element.type === "Numeric") {
        //     return "1".repeat(parseInt(element.values) || 0);
        //   } else if (element.type === "Special Character") {
        //     return element.values;
        //   }
        // });

        setPartNumbers(filteredElements);
        scrollToBottom()
      }

      // event.stopPropagation();
    } catch (error) {
      console.error("Error handling drop:", error);
    }
  };


  useEffect(() => {
    getFormElementFields();
  }, [type]);
  const getFormElementFields = async () => {
    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      const response = await axios.get(
        `${BASE_URL}/v1/purchase-name/get-form-ele`,
        { headers }
      );
      const FormFields = response.data.data;
      const filteredFields = FormFields.filter(field =>
        field.nomenclature_type === ((type === 'po-num') ? 'po' : 'parts')
      );
      setDragableItemsArrays(filteredFields)
      console.log(filteredFields);
    } catch (error) {

    }
  }



  useEffect(() => {
    getActiveFields();
  }, [type]);
  const getActiveFields = async () => {
    try {
      setLoading(true)
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      let fields,attributesField

      if (type === 'part-num') {
        fields = await axios.get(
          `${BASE_URL}/v1/part-nomen/active-part-nomen`,
          { headers }
        );
        attributesField = await axios.get(
          `${BASE_URL}/v1/part-nomen/get-attributes`,
          {
            params: {
                type:'ebom'
            },
            headers: headers,
        }
        );
        // setAttributesLength(attributesField.data.data.attribute_options.data.length)
      } else if (type === 'po-num') {
        fields = await axios.get(
          `${BASE_URL}/v1/purchase-name/get-active-po-name`,
          { headers }
        );
        // 
      } else if (type === 'assets-num') {
        fields = await axios.get(
          `${BASE_URL}/v1/asset/get-active-asset-name`,
          { headers }
        );
       
        // setAttributesLength(attributesField.data.data.attribute_options.data.length)
        // setAttributesLength(attributesField.data.data.attribute_options.data.length)
      }else if (type === 'warehouse') {
        fields = await axios.get(
          `${BASE_URL}/v1/warehouse/get-active-warehouse-name`,
          { headers }
        );
        // 
      }else if (type === 'location') {
        fields = await axios.get(
          `${BASE_URL}/v1/location/get-active-location-name`,
          { headers }
        );
        // 
      }

     
     

      if (type === 'part-num') {
        setRightSectionItems(fields.data.data.active_part_format);
        if (fields.data.data.active_part_format.length > 0) {
          setDropElements(true);
        }
      } else if (type === 'po-num') {
        setRightSectionItems(fields.data.data.active_purchase_format);
        if (fields.data.data.active_purchase_format.length > 0) {
          setDropElements(true);
        }
      } else if (type === 'assets-num') {
        setRightSectionItems(fields.data.data.active_asset_format);
        if (fields.data.data.active_asset_format.length > 0) {
          setDropElements(true);
        }
      }else if (type === 'warehouse') {
        setRightSectionItems(fields.data.data.active_warehouse_format);
        if (fields.data.data.active_warehouse_format.length > 0) {
          setDropElements(true);
        }
      }else if (type === 'location') {
        setRightSectionItems(fields.data.data.active_location_format);
        if (fields.data.data.active_location_format.length > 0) {
          setDropElements(true);
        }
      }

      if (fields.data.data.info !== '') {
        setSavedData(true)
      }

      // setSavedData(!!fields.data.data.info);

      // Reset savedData to false after 10 seconds
      // setTimeout(() => {
      //   setSavedData(false);
      // }, 10000);


      setIsAdmin(fields.data.data.is_admin)
      console.log(fields.data.data.is_admin)
      // console.log(attributeOptions.unitof_measurement.length)
      generateDynamicPartName();
      setLoading(false)
    } catch (error) {
      console.log(error);
    }

  }

  function isValidSpecialCharacter(value) {
    const specialCharacterPattern = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
    return specialCharacterPattern.test(value);
  }

  const validateFields = () => {
    const newErrors = {};


    if (Array.isArray(rightSectionItems) && rightSectionItems.length > 0) {
      rightSectionItems.map((item, index) => {
        // console.log(index);
        const hasUniqueItem = rightSectionItems.some((item) => item.is_unique);

        if (item && item.character_type !== "special_character"
        ) {
          if ((!item.character_length || item.character_length <= 0) && item.character_type !== 'input_variable'
            && item.character_type !== "date" && item.character_type !== "Calendar year"
            && item.character_type !== "financial_year") {
            newErrors[index] = "Please enter a valid character length";
          } else if (!item.default_value) {
            newErrors[index] = "Please enter a default value";
          } else if (!item.meaning && item.character_type !== "date" && item.character_type !== "Calendar year"
            && item.character_type !== "financial_year") {
            newErrors[index] = "Please enter a meaning";
          } else if (
            !item.variable_selected_type && item.character_type !== 'input_variable' && item.character_type !== "date" && item.character_type !== "Calendar year"
            && item.character_type !== "financial_year"
          ) {
            newErrors[index] = "Please select variables";
          } else if (item.variable_selected_type === 'Fixed' && (!item.fixed_variable_options || item.fixed_variable_options.length < 1)) {
            newErrors[index] = "Add the fixed variables";
          } else if (!hasUniqueItem) {
            // Display an error message or handle the lack of uniqueness as needed
            newErrors[index] = "";
            setUniqueErrors("Please select 1 unique part number");
            return;
          } else if (item.character_type !== "input_variable" && item.default_value.length !== item.character_length
            && item.character_type !== "date" && item.character_type !== "Calendar year"
            && item.character_type !== "financial_year"
          ) {
            if (item.default_value.length > item.character_length) {
              newErrors[index] = "Default value entered is greater than the character length";
            } else if (item.default_value.length < item.character_length) {
              newErrors[index] = "Default value entered is less than the character length";
            }

          }
        } else {
           if (!isValidSpecialCharacter(item.default_value)) {
            newErrors[index] = "Please enter only special characters";
          }
        }
      });
    } else {
      console.error("rightSectionItems is not defined or is an empty array.");
    }




    setErrors(newErrors);
    // setUniqueErrors(uniqueErrors)


    return Object.keys(newErrors).length === 0;
  };

  const handleErrors = () => {
    const isValid = validateFields();

    if (isValid && rightSectionItems.length > 0) {

      handleSave();

      setUniqueErrors('')
//       if(type !== 'po-num'){
// if(attributesLength < 1){
//       setOpenAttribute(true)
//       }
//       }
     
      


      // getActiveFields();
      // window.location.reload()
    } else {

     
      console.log(errors);
    }
  };



  const handleSave = async () => {
    const isVariation = rightSectionItems.some(item =>
      (item.character_type === 'input_variable') ||
      ((item.is_unique === false) && (item.variable_selected_type === 'Counter'))
    );

    console.log(isVariation)
    const savedData = rightSectionItems.map((item, index) => {
      const character_length = item.character_length ? item.character_length : 0;
      const meaning = item.meaning ? item.meaning : "";
      const variable_selected_type = item.variable_selected_type
        ? item.variable_selected_type
        : "";
      const fixed_variable_options = item.fixed_variable_options ? item.fixed_variable_options : [];
      const is_unique = item.is_unique ? item.is_unique : false
      const default_value = item.default_value ? item.default_value : ''
      const position = index;
      // const is_variation = isVariation;

      return {
        // character_type: item.character_type,
        // displayType:item.displayType,
        // character_logo: item.character_logo,
        // character_placeHolder: item.character_placeHolder,
        form_element_id:item._id,
        meaning_placeholder: item.meaning_placeholder,
        character_length,
        meaning,
        variable_selected_type,
        position,
        fixed_variable_options,
        default_value,
        is_unique,
        // is_variation
      };
    });

    // console.log(isVariation)
    const savedDataJSON = JSON.stringify(savedData);
    const partNameNomenclature = generateDynamicPartName();
    console.log(savedDataJSON);

    try {
      const headers = HEADERS
      if (type === 'part-num') {
        await axios.post(
          `${BASE_URL}/v1/part-nomen/part-nomen`,
          { data: savedDataJSON, partNameNomenclature: partNameNomenclature },
          { headers }
        );
      } else if (type === 'po-num') {
        await axios.post(
          `${BASE_URL}/v1/purchase-name/po-name`,
          { data: savedDataJSON, purchaseOrderName: partNameNomenclature },
          { headers }
        );
      } else if (type === 'assets-num') {
        await axios.post(
          `${BASE_URL}/v1/asset/asset-name`,
          { data: savedDataJSON, purchaseOrderName: partNameNomenclature },
          { headers }
        );
      }else if (type === 'warehouse') {
        await axios.post(
          `${BASE_URL}/v1/warehouse/warehouse-name`,
          { data: savedDataJSON, wareHouseName: partNameNomenclature },
          { headers }
        );
      }else if (type === 'location') {
        await axios.post(
          `${BASE_URL}/v1/location/location-name`,
          { data: savedDataJSON, locationName: partNameNomenclature },
          { headers }
        );
      }

      setSavedData(true)
      getActiveFields()
      // window.location.reload()
    } catch (error) {
      console.log(error)
    }



  };
  // window.location.reload()
  const DateFormate = [
    { value: 'DD-MM-YYYY', label: 'DD-MM-YYYY' },
    { value: 'MM-DD-YYYY', label: 'MM-DD-YYYY' },
    { value: 'YYYY-MM-DD', label: 'YYYY-MM-DD' },
    { value: 'DD-MMM-YYYY', label: 'DD-MMM-YYYY' },
    { value: 'D', label: 'D' },
    { value: 'M', label: 'M' },
    { value: 'D-MM', label: 'D-MM' },
    { value: 'MM-DD-YY', label: 'MM-DD-YY' },
    { value: 'YYYY-MM-DD-HH-MM', label: 'YYYY-MM-DD-HH-MM' },
    { value: 'YYYY', label: 'YYYY' },
    { value: 'YY', label: 'YY' },
  ];
  const YearFormate = [
    { value: 'YYYY', label: 'YYYY' },
    { value: 'YY', label: 'YY' },
  ];
  const financialYears = [
    { value: 'FY-YYYY', label: 'FY-YYYY' },
    { value: 'YYYY-YY', label: 'YYYY-YY' },
    { value: 'YYYY-YYYY', label: 'YYYY-YYYY' },
    { value: 'FY-YYYY-FY-YYYY', label: 'FY-YYYY-FY-YYYY' },
    // { value: 'October 1st to September 30th', label: 'October 1st to September 30th' }
  ];

  const calendarYears = Array.from({ length: 10 }, (_, i) => {
    const year = (new Date().getFullYear() + i).toString();
    return { value: year, label: year };
  });
  return (
    <>

      <div className="part-num-page" style={{ display: openAttribute ? 'none' : '' }}>
        <div className="part-num-top">
          <div className="part-num-content">
            {/* <img src={`${ASSET_PREFIX_URL}template-back-btn.svg`} alt="back" /> */}
            {/* <div className="part-num-line"></div> */}
            <span>
              {type === 'part-num' && 'Part number and naming'}
              {type === 'po-num' && 'Purchase order number'}
              {type === 'assets-num' && 'Assets number'}
              {type === 'warehouse' && 'Warehouse number'}
              {type === 'location' && 'Location number'}

            </span>
            {savedData &&
              <div className="saved-text-part">
                <img src={`${ASSET_PREFIX_URL}cloud-save.png`} alt="" style={{ width: '24px', height: '24px' }} />
                <span >saved</span>
              </div>}

          </div>
          {isAdmin && <div className="part-num-top-btns">
            <span className="unique-error" >{uniqueErrors}</span>
            {(type !== 'po-num' && type !== 'warehouse'&&type !== 'location' && type !== 'assets-num') && <button className="part-num-attributes" onClick={handleOpenAttribute}>
              {type === 'part-num' && 'Add part attributes'}
              {type === 'po-num' && 'Add PO attributes'}
              {type === 'assets-num' && 'Add Assets attributes'}
            </button>}


            <button className="part-num-active" onClick={handleErrors}>
              Save
            </button>
          </div>}


          {/* {!saveButton ? (
          <button className="part-num-inactive" onClick={handleErrors}>
            Save
          </button>
        ) : (
          <button className={`part-num-active`} onClick={handleErrors}>
            save
          </button>
        )} */}
        </div>
        <div className="part-num-bottom">
          <div className="part-num-bottom-left">
            <div className="part-num-bottom-left-text">
              <span className="part-num-bottom-left-title">
                Choose naming convention
              </span>
              <span className="part-num-bottom-left-desc">
                Drag and drop form elements in additional section
              </span>
            </div>
            <div className="part-num-bottom-left-dragable">
              {dragableItemsArrays.map((ele) => (
                <div
                  className="part-num-bottom-left-items"
                  draggable
                  onDragStart={() => handleDragStart(ele)}
                >
                  <img src={`${ASSET_PREFIX_URL}${ele.character_logo}`} alt="" draggable="false" />

                  <span>{ele.displayType}</span>
                </div>
              ))}
            </div>
            {/* <div className="part-num-bottom-left-msg">
              <span>
                Message to show when this will get un-lock or get enabled
              </span>
            </div> */}
          </div>
          <div className="part-num-bottom-right">
            <div className="part-num-bottom-nomenclature">
              <span className="part-num-bottom-right-title">
                {type === 'part-num' && ' Part naming nomenclature '}
                {type === 'po-num' && ' PO number '}
                {type === 'assets-num' && ' Assets number '}
                {type === 'warehouse' && ' Warehouse number '}
                {type === 'location' && ' Location number '}
              </span>
              {/* <br /> */}
              {(type !== 'po-num' && type !== 'warehouse' && type !== 'location') && <span style={{ marginBottom: '5px' }}>Step 1 of 2</span>}

              <span className="part-num-bottom-right-desc">
                Drag and drop the fields in the order you want to create the
                {type === 'part-num' && ' part '}
                {type === 'po-num' && ' PO '}
                {type === 'assets-num' && ' Assets '}
                {type === 'warehouse' && ' Warehouse '}
                {type === 'location' && ' Location '}
                naming system for your company.
              </span>
              <span className="part-num-bottom-right-desc">
                For example, AA-11-BBBB. Drag alphabet from the left and specify length = 2
                in the drop-down. Similarly, do the same for numeric and set length=2
                and so on.
              </span>
              <span className="part-num-bottom-right-desc-high">
                There can only be 1 unique  {type === 'part-num' && 'part'}
                {type === 'po-num' && 'PO'} {type === 'assets-num' && 'assets'} number in the entire
                nomenclature.
              </span>
            </div>
            {dropElements && (
              <div className="part-num-bottom-partName">
                <span className="part-num-bottom-right-partName-desc">
                  {type === 'part-num' && 'partname'}
                  {type === 'po-num' && 'PO number'}
                  {type === 'assets-num' && 'Assets number'} based on your inputs
                </span>
                <span className="part-num-bottom-right-partName-title">
                  {" "}
                  {type === 'po-num' ? generateDatePartName() :generateDynamicPartName()}
                  
                </span>
              </div>
            )}

            <div
              className="part-number-dropping-zone"

              onDragOver={(e) => handleDragOver(e)}
              onDrop={handleDrop}
              ref={containerRef}
            >
              {!dropElements && (
                <div className="empty-drop-message">
                  <img
                    src={`${ASSET_PREFIX_URL}template-adding-button.svg`}
                    alt=""
                  />
                  <span>Add fields to create</span>
                </div>
              )}
              {loading ? <Loading /> : <>
                {rightSectionItems.map(
                  (ele, index) =>
                    ele && (
                      <>
                        <div
                          className={`horizontal-line ${isDraggingOver === index ? 'dragging-over-class' : 'not-dragging-over-class'}`}
                        />
                        <div className="part-num-bottom-addition"
                          key={index}
                          draggable
                          onDragStart={(e) => handleDragingStart(e, ele, index)}
                          onDragOver={(e) => handleDragingOver(e, index)}
                          onDragLeave={() => handleDragLeave()}
                          onDrop={(e) => handleingDrop(e, index)}
                          onClick={() => handlegetId(index)}>
                          <div className="part-num-bottom-top">
                            <div className="part-num-bottom-totype">
                              <img src={`${ASSET_PREFIX_URL}${ele.character_logo}`} alt="" draggable='false' />
                              {/* <img src={`${ASSET_PREFIX_URL}part-num-alpha.png`} /> */}
                              <span>{ele.displayType}</span>
                            </div>
                            <div
                              className="part-num-bottom-btn-cancel"
                              onClick={() => handleItemDelete(ele, index)}
                            >
                              <img
                                className="part-num-bottom-btn-cancel-img"
                                src={`${ASSET_PREFIX_URL}close-icon-part-num.png`}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className='part-num-bottom-cont' >

                            <div className="part-num-bottom-box">
                              <Dots />
                            </div>
                            {(ele.character_type !== "special_character" &&
                              ele.character_type !== "date" &&
                              ele.character_type !== "Calendar year" &&
                              ele.character_type !== "financial_year"
                            ) && (

                                <div className="part-num-bottom-alpha-divs">
                                  {ele.character_type === "input_variable" ? '' :
                                    <div className="part-num-bottom-alpha">
                                      <span className="part-num-bottom-alpha-title">
                                        No. of characters*
                                      </span>
                                      <input
                                        className="part-num-bottom-alpha-desc"
                                        type="number"
                                        placeholder={ele.character_placeHolder}
                                        value={ele.character_length}
                                        onChange={(e) => {
                                          handleInputChange(
                                            index,
                                            e.target.value,
                                            ele.character_type
                                          );
                                          validateFields(); // Call validateFields on input change
                                        }}

                                      />
                                    </div>
                                  }

                                  <div className="part-num-bottom-alpha">
                                    <span className="part-num-bottom-alpha-title">
                                      Default value*
                                    </span>
                                    <input
                                      className="part-num-bottom-alpha-desc"
                                      type={ele.character_type === 'numeric' ?'number':'text'}
                                      disabled={ele.character_type !== 'input_variable' && !ele.character_length}
                                      maxLength={ele.character_type !== 'input_variable' && ele.character_length}
                                      placeholder="e.g. REV01"
                                      value={ele.default_value}
                                      onChange={(e) => {
                                        handleDefaultValueChange(
                                          index,
                                          e.target.value

                                        ); validateFields();
                                      }}
                                    />
                                  </div>

                                  <div className="part-num-bottom-alpha-select">
                                    <span className="part-num-bottom-alpha-title">
                                      Meaning*
                                    </span>
                                    <input
                                      className="part-num-bottom-alpha-desc-mean"
                                      type="text"
                                      value={ele.meaning}
                                      placeholder="Enter ‘meaning’ e.g. Team "
                                      onChange={(e) => {
                                        handlemeaningChange(
                                          index,
                                          e.target.value,
                                          ele.type
                                        ); validateFields();
                                      }
                                      }
                                    />
                                  </div>
                                  {ele.character_type === "input_variable" ? '' :
                                    <div className="part-num-bottom-alpha">
                                      <span className="part-num-bottom-alpha-title">
                                        Variable*
                                      </span>
                                      <div className="part-num-bottom-btn-fixed">
                                        <Select
                                          options={formattedVersions}
                                          styles={customStyles}
                                          value={
                                            ele.variable_selected_type
                                              ? {
                                                value: ele.variable_selected_type,
                                                label: ele.variable_selected_type,
                                              }
                                              : ''
                                          }
                                          // value={ele.variable_selected_type ? ele.variable_selected_type : selectedOptions.variable_selected_type}
                                          onChange={(selected) => {
                                            handleSelectChange(index, selected, 'fixed'); validateFields()
                                          }
                                          }
                                        />
                                        {ele.variable_selected_type === "Fixed" ? (
                                          <button onClick={() => handleopenPopUp(index, ele.fixed_variable_options)}>
                                            Add Variables*
                                          </button>
                                        ) : (
                                          <button style={{ background: "#CABDFC" }}>
                                            Add Variables*
                                          </button>
                                        )}

                                      </div>
                                    </div>}
                                  {(ele.variable_selected_type === 'Fixed' ||ele.variable_selected_type === 'Static' || ele.character_type === "input_variable" || !ele.variable_selected_type||
                                    ele.character_type === "alphabet"
                                  ) ? "" :
                                    <div className="part-num-bottom-alpha-radio">
                                      <span className="part-num-bottom-alpha-title">
                                        Unique*
                                      </span>
                                      <input

                                        type="radio"
                                        // value={ele.is_unique}
                                        checked={ele.is_unique} // Set the checked attribute based on the selected index
                                        onChange={() => { handleRadioButtonChange(index, true); }}
                                      />
                                    </div>}


                                </div>
                              )}
                            {ele.character_type === "special_character" && (
                              <div className="part-num-bottom-alpha-divs">
                                <div className="part-num-bottom-alpha">
                                  <span className="part-num-bottom-alpha-title">
                                    {ele.character_type}
                                  </span>
                                  <input
                                    className="part-num-bottom-alpha-char"
                                    type="text"
                                    pattern="[A-Za-z]+"
                                    title="Only characters are allowed"
                                    placeholder={ele.character_placeHolder}
                                    value={ele.default_value}
                                    onChange={(e) => {
                                      handleDefaultValueChange(index, e.target.value, ele.character_type);
                                      validateFields(); // Call validateFields after handling input change
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                            {ele.character_type === "date" && (
                              <div className="part-num-bottom-alpha-divs">
                                <div className="part-num-bottom-alpha">
                                  <span className="part-num-bottom-alpha-title">
                                    Date format*
                                  </span>

                                  <Select
                                    value={
                                      ele.default_value
                                        ? {
                                          value: ele.default_value,
                                          label: ele.default_value,
                                        }
                                        : ''
                                    }
                                    styles={customDateStyles}
                                    onChange={(selected) => {
                                      handleSelectChange(index, selected, 'date'); validateFields()
                                    }}
                                    // value={calendarYear}
                                    // onChange={setCalendarYear}
                                    options={DateFormate}
                                    placeholder="Select calendar year"
                                  />
                                </div>
                              </div>
                            )}
                            {ele.character_type === "Calendar year" && (
                              <div className="part-num-bottom-alpha-divs">
                                <div className="part-num-bottom-alpha">
                                  <span className="part-num-bottom-alpha-title">
                                    Calander year format*
                                  </span>
                                  <Select
                                    value={
                                      ele.default_value
                                        ? {
                                          value: ele.default_value,
                                          label: ele.default_value,
                                        }
                                        : ''
                                    }
                                    styles={customDateStyles}
                                    onChange={(selected) => {
                                      handleSelectChange(index, selected, 'calander'); validateFields()
                                    }}
                                    // value={calendarYear}
                                    // onChange={setCalendarYear}
                                    options={YearFormate}
                                    placeholder="Select calendar year"
                                  />
                                </div>
                              </div>
                            )}
                            {ele.character_type === "financial_year" && (
                              <div className="part-num-bottom-alpha-divs">
                                <div className="part-num-bottom-alpha">
                                  <span className="part-num-bottom-alpha-title">
                                    Financial year format*
                                  </span>
                                  <Select
                                    styles={customDateStyles}
                                    value={
                                      ele.default_value
                                        ? {
                                          value: ele.default_value,
                                          label: ele.default_value,
                                        }
                                        : ''
                                    }
                                    onChange={(selected) => {
                                      handleSelectChange(index, selected, 'calander'); validateFields()
                                    }}
                                    // value={financialYear}
                                    // styles={customStyles}
                                    // onChange={setFinancialYear}
                                    options={financialYears}
                                    placeholder="Select financial year"
                                  />
                                </div>
                              </div>
                            )}



                          </div>
                          <div>
                            <span className="fixed-variable-text-error">{errors[index]}</span>
                            {/* {(ele.variable_selected_type === "Fixed" && ele.variableMeaning === null) &&
                        <span className="fixed-variable-text-error">Please add fixed variables</span>} */}
                            {(ele.variable_selected_type === 'Fixed' && ele.fixed_variable_options) &&
                              <div className="fixed-variables-div" style={{ overflowX: 'auto' }}>
                                {ele.fixed_variable_options.slice(0, visibleItems).map((item, index) => (
                                  <div key={index} className="fixed-variables-list">
                                    <span>{item.variable},</span>
                                    <span>{item.meaning}</span>
                                  </div>
                                ))}
                                {ele.fixed_variable_options.length > visibleItems && (
                                  <button onClick={() => handleopenPopUp(index, ele.fixed_variable_options)} className="view-all-btn">View All ({ele.fixed_variable_options.length - visibleItems})</button>
                                )}
                              </div>
                            }

                          </div>

                        </div>

                      </>


                    )
                )}
              </>}



            </div>
          </div>
        </div>
      </div>


      {openPopUp && (
        <PartNumberPopUp
          onclose={handleopenPopUp}
          onupdate={handleFixedVariables}
          defaultVariables={intitialValues}
          characterLength={rightSectionItems[indexedField].character_length}
          validateFields={validateFields}
        />
      )}
      {openAttribute && (<PartAttributes onclose={handleOpenAttribute} handleSavePartNumber={handleSave} type={type} />)}
    </>
  );
}

export default PartNumber;
